import en_AR from "./en_AR"
import en_CL from "./en_CL"
import en_CO from "./en_CO"
import en_CR from "./en_CR"
import en_DO from "./en_DO"
import en_ES from "./en_ES"
import en_KE from "./en_KE"
import en_MX from "./en_MX"
import en_NG from "./en_NG"
import en_PA from "./en_PA"
import en_PE from "./en_PE"
import en_US from "./en_US"
import en_ZA from "./en_ZA"

const langs = {
  en_AR,
  en_CL,
  en_CO,
  en_CR,
  en_DO,
  en_ES,
  en_KE,
  en_MX,
  en_NG,
  en_PA,
  en_PE,
  en_US,
  en_ZA
}

export default langs
