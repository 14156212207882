<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path d="M20.2871 11.3069C20.1987 11.2702 20.1017 11.25 20 11.25C19.8983 11.25 19.8013 11.2702 19.7129 11.3069C19.6245 11.3435 19.5416 11.3978 19.4697 11.4697L14.4697 16.4697C14.1768 16.7626 14.1768 17.2374 14.4697 17.5303C14.7626 17.8232 15.2374 17.8232 15.5303 17.5303L19.25 13.8107V24C19.25 24.4142 19.5858 24.75 20 24.75C20.4142 24.75 20.75 24.4142 20.75 24V13.8107L24.4697 17.5303C24.7626 17.8232 25.2374 17.8232 25.5303 17.5303C25.8232 17.2374 25.8232 16.7626 25.5303 16.4697L20.5303 11.4697C20.4584 11.3978 20.3755 11.3435 20.2871 11.3069Z" fill="currentColor"/>
  <path d="M12 24.25C12.4142 24.25 12.75 24.5858 12.75 25V27C12.75 27.3315 12.8817 27.6495 13.1161 27.8839C13.3505 28.1183 13.6685 28.25 14 28.25H26C26.3315 28.25 26.6495 28.1183 26.8839 27.8839C27.1183 27.6495 27.25 27.3315 27.25 27V25C27.25 24.5858 27.5858 24.25 28 24.25C28.4142 24.25 28.75 24.5858 28.75 25V27C28.75 27.7293 28.4603 28.4288 27.9445 28.9445C27.4288 29.4603 26.7293 29.75 26 29.75H14C13.2707 29.75 12.5712 29.4603 12.0555 28.9445C11.5397 28.4288 11.25 27.7293 11.25 27V25C11.25 24.5858 11.5858 24.25 12 24.25Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>
</template>

<script>
export default {
  name: "IconImports"
}
</script>
