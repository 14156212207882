<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1962 13.1825C11.886 13.4616 11.75 13.7732 11.75 14V29C11.75 29.4142 11.4142 29.75 11 29.75C10.5858 29.75 10.25 29.4142 10.25 29V14C10.25 13.2268 10.6695 12.5384 11.1927 12.0675C11.7212 11.5919 12.4493 11.25 13.2222 11.25H18.7778C19.5507 11.25 20.2788 11.5919 20.8073 12.0675C21.3305 12.5384 21.75 13.2268 21.75 14V29C21.75 29.4142 21.4142 29.75 21 29.75C20.5858 29.75 20.25 29.4142 20.25 29V14C20.25 13.7732 20.114 13.4616 19.8038 13.1825C19.499 12.9081 19.116 12.75 18.7778 12.75H13.2222C12.884 12.75 12.501 12.9081 12.1962 13.1825Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.25 16C22.25 15.5858 22.5858 15.25 23 15.25H25.5C26.3158 15.25 27.1025 15.5715 27.6873 16.0393C28.2589 16.4966 28.75 17.1908 28.75 18V29C28.75 29.4142 28.4142 29.75 28 29.75C27.5858 29.75 27.25 29.4142 27.25 29V18C27.25 17.8092 27.1161 17.5034 26.7502 17.2107C26.3975 16.9285 25.9342 16.75 25.5 16.75H23C22.5858 16.75 22.25 16.4142 22.25 16Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.25 29C9.25 28.5858 9.58579 28.25 10 28.25L30 28.25C30.4142 28.25 30.75 28.5858 30.75 29C30.75 29.4142 30.4142 29.75 30 29.75L10 29.75C9.58579 29.75 9.25 29.4142 9.25 29Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0714 16C13.0714 15.4871 13.4871 15.0714 14 15.0714H14.5C15.0128 15.0714 15.4286 15.4871 15.4286 16C15.4286 16.5128 15.0128 16.9286 14.5 16.9286H14C13.4871 16.9286 13.0714 16.5128 13.0714 16Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0714 20C13.0714 19.4871 13.4871 19.0714 14 19.0714H14.5C15.0128 19.0714 15.4286 19.4871 15.4286 20C15.4286 20.5128 15.0128 20.9286 14.5 20.9286H14C13.4871 20.9286 13.0714 20.5128 13.0714 20Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5714 20C23.5714 19.4871 23.9871 19.0714 24.5 19.0714H25.0001C25.5129 19.0714 25.9287 19.4871 25.9287 20C25.9287 20.5128 25.5129 20.9286 25.0001 20.9286H24.5C23.9871 20.9286 23.5714 20.5128 23.5714 20Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5714 24C23.5714 23.4871 23.9871 23.0714 24.5 23.0714H25.0001C25.5129 23.0714 25.9287 23.4871 25.9287 24C25.9287 24.5128 25.5129 24.9286 25.0001 24.9286H24.5C23.9871 24.9286 23.5714 24.5128 23.5714 24Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0714 24C13.0714 23.4871 13.4871 23.0714 14 23.0714H14.5C15.0128 23.0714 15.4286 23.4871 15.4286 24C15.4286 24.5128 15.0128 24.9286 14.5 24.9286H14C13.4871 24.9286 13.0714 24.5128 13.0714 24Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5714 16C16.5714 15.4871 16.9871 15.0714 17.5 15.0714H18.0001C18.5129 15.0714 18.9287 15.4871 18.9287 16C18.9287 16.5128 18.5129 16.9286 18.0001 16.9286H17.5C16.9871 16.9286 16.5714 16.5128 16.5714 16Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5714 20C16.5714 19.4871 16.9871 19.0714 17.5 19.0714H18.0001C18.5129 19.0714 18.9287 19.4871 18.9287 20C18.9287 20.5128 18.5129 20.9286 18.0001 20.9286H17.5C16.9871 20.9286 16.5714 20.5128 16.5714 20Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5714 24C16.5714 23.4871 16.9871 23.0714 17.5 23.0714H18.0001C18.5129 23.0714 18.9287 23.4871 18.9287 24C18.9287 24.5128 18.5129 24.9286 18.0001 24.9286H17.5C16.9871 24.9286 16.5714 24.5128 16.5714 24Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMultiCompany"
}
</script>
