import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

const inistialState = () => ({
  loading: false,
  error: false,
  hideEcStep: false,
})

const state = inistialState()

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
