<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 18C14.25 16.4812 15.4812 15.25 17 15.25C18.5188 15.25 19.75 16.4812 19.75 18C19.75 19.5188 18.5188 20.75 17 20.75C15.4812 20.75 14.25 19.5188 14.25 18ZM17 16.75C16.3096 16.75 15.75 17.3096 15.75 18C15.75 18.6904 16.3096 19.25 17 19.25C17.6904 19.25 18.25 18.6904 18.25 18C18.25 17.3096 17.6904 16.75 17 16.75Z" fill="currentColor"/>
  <path d="M23 15.25C22.5858 15.25 22.25 15.5858 22.25 16C22.25 16.4142 22.5858 16.75 23 16.75H25C25.4142 16.75 25.75 16.4142 25.75 16C25.75 15.5858 25.4142 15.25 25 15.25H23Z" fill="currentColor"/>
  <path d="M22.25 20C22.25 19.5858 22.5858 19.25 23 19.25H25C25.4142 19.25 25.75 19.5858 25.75 20C25.75 20.4142 25.4142 20.75 25 20.75H23C22.5858 20.75 22.25 20.4142 22.25 20Z" fill="currentColor"/>
  <path d="M15 23.25C14.5858 23.25 14.25 23.5858 14.25 24C14.25 24.4142 14.5858 24.75 15 24.75H25C25.4142 24.75 25.75 24.4142 25.75 24C25.75 23.5858 25.4142 23.25 25 23.25H15Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 15C10.25 12.9289 11.9289 11.25 14 11.25H26C28.0711 11.25 29.75 12.9289 29.75 15V25C29.75 27.0711 28.0711 28.75 26 28.75H14C11.9289 28.75 10.25 27.0711 10.25 25V15ZM14 12.75C12.7574 12.75 11.75 13.7574 11.75 15V25C11.75 26.2426 12.7574 27.25 14 27.25H26C27.2426 27.25 28.25 26.2426 28.25 25V15C28.25 13.7574 27.2426 12.75 26 12.75H14Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>




</template>

<script>
export default {
  name: "IconBadgeAc"
}
</script>
