import { render, staticRenderFns } from "./IconBadgeFe.vue?vue&type=template&id=4eed611a&"
import script from "./IconBadgeFe.vue?vue&type=script&lang=js&"
export * from "./IconBadgeFe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports