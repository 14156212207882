<template>
  <div v-if="!loading && show">
    <NewFirstStepsComponent :achievements="achievements" :skipModal="skipModal" onlyByProfile />
  </div>
  <div v-else>
    <div v-show="show" class="skeleton-new" />
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { isBefore } from "date-fns"
import NewFirstStepsComponent from "@/components/NewFirstStepsComponent.vue"
import { mapGetters, mapActions } from "vuex"
import { request } from "@alegradev/smile-ui-alegra"
import { baseApiUrl, firstStepsCookieBaseName, checkAllStepsCompleted } from "@/utils/appHelpers"

export default {
  components: {
    NewFirstStepsComponent
  },
  data: () => ({
    loading: true,
    show: true,
    client: false,
    items: false,
    invoice: false,
    payment: false,
    achievements: [
      {
        id: "badge-client",
        title: "byProfile.invoicing.client.title",
        actions: ["start"],
        link: "/client/add",
        stepName: "create client"
      },
      {
        id: "badge-product",
        title: "byProfile.invoicing.product.title",
        actions: ["start"],
        link: "/item/add",
        stepName: "create product"
      },
      {
        id: "badge-invoice",
        title: "byProfile.invoicing.invoice.title",
        actions: ["start"],
        link: "/invoice/add",
        stepName: "first invoice"
      },
      {
        id: "badge-config-alt",
        title: "byProfile.invoicing.payment.title",
        actions: ["start"],
        link: "/transaction/in",
        stepName: "received payments"
      }
    ],
    skipModal: {
      title: "",
      text: "",
      help: ""
    }
  }),
  computed: {
    ...mapGetters({
      company: "session/company",
      user: "session/user"
    }),

    notShowAgainCookieName() {
      return `${firstStepsCookieBaseName}-no-show-${this.company.id}`
    }
  },
  async mounted() {
    if (Cookies.get(this.notShowAgainCookieName)) {
      this.loading = false
      this.show = false
      return
    }

    await this.init()
    this.setInfo()
  },
  methods: {
    ...mapActions({
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async init() {
      this.loading = true

      try {
        const respClients = await request({
          url: `${baseApiUrl}v1/contacts?start=0&limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.client = respClients.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respItems = await request({
          url: `${baseApiUrl}v1/items?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.items = respItems.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respInvoices = await request({
          url: `${baseApiUrl}v1/invoices?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.invoice = respInvoices.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respPaymentsIn = await request({
          url: `${baseApiUrl}v1/payments?limit=1&type=in`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.payment = respPaymentsIn.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      //this.loading = false
    },

    getIndex(id) {
      return this.achievements.findIndex(achievement => achievement.id === id)
    },

    setInfo() {
      let client = this.getIndex("badge-client")
      let items = this.getIndex("badge-product")
      let invoice = this.getIndex("badge-invoice")
      let payment = this.getIndex("badge-config-alt")

      if (client !== -1 && this.client) {
        this.achievements[client].actions = ["completed"]
      }

      if (items !== -1 && this.items) {
        this.achievements[items].actions = ["completed"]
      }

      if (invoice !== -1 && this.invoice) {
        this.achievements[invoice].actions = ["completed"]
      }

      if (payment !== -1 && this.payment) {
        this.achievements[payment].actions = ["completed"]
      }

      if (
        !Cookies.get(this.notShowAgainCookieName) &&
        checkAllStepsCompleted(this.achievements) &&
        isBefore(new Date(this.company.registryDate.split(" ")[0]), new Date("2023/01/09"))
      ) {
        Cookies.set(this.notShowAgainCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })

        this.show = false
      }

      this.loading = false
    }
  }
}
</script>
