<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<path d="M17.5 16.5C17.5 17.0523 17.0523 17.5 16.5 17.5C15.9477 17.5 15.5 17.0523 15.5 16.5C15.5 15.9477 15.9477 15.5 16.5 15.5C17.0523 15.5 17.5 15.9477 17.5 16.5Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15V18.859C11.25 19.5947 11.5419 20.3005 12.0627 20.8213L20.1786 28.9373C20.4363 29.195 20.7423 29.3995 21.079 29.5389C21.4157 29.6784 21.7765 29.7502 22.141 29.7502C22.5055 29.7502 22.8663 29.6784 23.203 29.5389C23.5398 29.3995 23.8457 29.195 24.1034 28.9373L28.9373 24.1034C29.195 23.8457 29.3995 23.5398 29.5389 23.203C29.6784 22.8663 29.7502 22.5055 29.7502 22.141C29.7502 21.7765 29.6784 21.4157 29.5389 21.079C29.3995 20.7423 29.195 20.4363 28.9373 20.1786L20.8203 12.0626C20.3002 11.5426 19.5948 11.2503 18.8593 11.25H15ZM13.409 13.409C13.831 12.9871 14.4033 12.75 15 12.75H18.8587C19.1965 12.7502 19.5208 12.8845 19.7597 13.1234L27.8767 21.2394C27.9951 21.3578 28.0891 21.4983 28.1531 21.653C28.2172 21.8077 28.2502 21.9735 28.2502 22.141C28.2502 22.3085 28.2172 22.4743 28.1531 22.629C28.0891 22.7837 27.9951 22.9242 27.8767 23.0426L23.0427 27.8767C22.9243 27.9951 22.7837 28.0891 22.629 28.1531C22.4743 28.2172 22.3085 28.2502 22.141 28.2502C21.9735 28.2502 21.8077 28.2172 21.653 28.1531C21.4983 28.0891 21.3578 27.9951 21.2394 27.8767L13.1233 19.7607C12.8841 19.5215 12.75 19.1973 12.75 18.859V15C12.75 14.4033 12.9871 13.831 13.409 13.409Z" fill="currentColor"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>
</template>

<script>
export default {
  name: "IconBadgeClient"
}
</script>
