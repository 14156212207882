<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7215 12.3036C19.9003 12.2321 20.0997 12.2321 20.2785 12.3036L30.2785 16.3036C30.5633 16.4175 30.75 16.6933 30.75 17V23C30.75 23.4142 30.4142 23.75 30 23.75C29.5858 23.75 29.25 23.4142 29.25 23V18.1078L26.75 19.1078V24C26.75 25.2236 25.7962 26.1831 24.5781 26.7921C23.3248 27.4188 21.6777 27.75 20 27.75C18.3223 27.75 16.6752 27.4188 15.4219 26.7921C14.2038 26.1831 13.25 25.2236 13.25 24V19.1078L9.72146 17.6964C9.43671 17.5825 9.25 17.3067 9.25 17C9.25 16.6933 9.43671 16.4175 9.72146 16.3036L19.7215 12.3036ZM14.2943 17.91C14.2838 17.9055 14.2731 17.9012 14.2623 17.8972L12.0194 17L20 13.8078L27.9806 17L25.7377 17.8972C25.7269 17.9012 25.7162 17.9055 25.7057 17.9099L20 20.1922L14.2943 17.91ZM14.75 19.7078V24C14.75 24.3677 15.0605 24.9344 16.0928 25.4505C17.09 25.9491 18.4951 26.25 20 26.25C21.5049 26.25 22.91 25.9491 23.9072 25.4505C24.9395 24.9344 25.25 24.3677 25.25 24V19.7078L20.2785 21.6964C20.0997 21.7679 19.9003 21.7679 19.7215 21.6964L14.75 19.7078Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>





</template>

<script>
export default {
  name: "IconBadgeAcademy"
}
</script>
