// mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload
  },
  SET_ERROR: (state, payload) => {
    state.error = payload
  },
  SET_HIDE_EC_STEP: (state, payload) => {
    state.hideEcStep = payload
  }
}

export default mutations
