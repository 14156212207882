<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.6465 13.25C24.32 12.0957 23.2588 11.25 22 11.25C20.7412 11.25 19.68 12.0957 19.3535 13.25H12C11.5858 13.25 11.25 13.5858 11.25 14C11.25 14.4142 11.5858 14.75 12 14.75H19.3535C19.68 15.9043 20.7412 16.75 22 16.75C23.2588 16.75 24.32 15.9043 24.6465 14.75H28C28.4142 14.75 28.75 14.4142 28.75 14C28.75 13.5858 28.4142 13.25 28 13.25H24.6465ZM22 12.75C21.3096 12.75 20.75 13.3096 20.75 14C20.75 14.6904 21.3096 15.25 22 15.25C22.6904 15.25 23.25 14.6904 23.25 14C23.25 13.3096 22.6904 12.75 22 12.75Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 17.25C17.2588 17.25 18.32 18.0957 18.6465 19.25H28C28.4142 19.25 28.75 19.5858 28.75 20C28.75 20.4142 28.4142 20.75 28 20.75H18.6465C18.32 21.9043 17.2588 22.75 16 22.75C14.7412 22.75 13.68 21.9043 13.3535 20.75H12C11.5858 20.75 11.25 20.4142 11.25 20C11.25 19.5858 11.5858 19.25 12 19.25H13.3535C13.68 18.0957 14.7412 17.25 16 17.25ZM16 18.75C15.3096 18.75 14.75 19.3096 14.75 20C14.75 20.6904 15.3096 21.25 16 21.25C16.6904 21.25 17.25 20.6904 17.25 20C17.25 19.3096 16.6904 18.75 16 18.75Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6465 25.25C27.32 24.0957 26.2588 23.25 25 23.25C23.7412 23.25 22.68 24.0957 22.3535 25.25H12C11.5858 25.25 11.25 25.5858 11.25 26C11.25 26.4142 11.5858 26.75 12 26.75H22.3535C22.68 27.9043 23.7412 28.75 25 28.75C26.2588 28.75 27.32 27.9043 27.6465 26.75H28C28.4142 26.75 28.75 26.4142 28.75 26C28.75 25.5858 28.4142 25.25 28 25.25H27.6465ZM23.75 26C23.75 25.3096 24.3096 24.75 25 24.75C25.6904 24.75 26.25 25.3096 26.25 26C26.25 26.6904 25.6904 27.25 25 27.25C24.3096 27.25 23.75 26.6904 23.75 26Z" fill="currentColor"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "IconBadgeInvoice"
}
</script>
