<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<path d="M20.75 18.25V18C20.75 17.5858 20.4142 17.25 20 17.25C19.5858 17.25 19.25 17.5858 19.25 18V18.2639C18.7452 18.3203 18.2716 18.5464 17.909 18.909C17.4871 19.331 17.25 19.9033 17.25 20.5C17.25 21.0967 17.4871 21.669 17.909 22.091C18.331 22.5129 18.9033 22.75 19.5 22.75H20.5C20.6989 22.75 20.8897 22.829 21.0303 22.9697C21.171 23.1103 21.25 23.3011 21.25 23.5C21.25 23.6989 21.171 23.8897 21.0303 24.0303C20.8897 24.171 20.6989 24.25 20.5 24.25H18C17.5858 24.25 17.25 24.5858 17.25 25C17.25 25.4142 17.5858 25.75 18 25.75H19.25V26C19.25 26.4142 19.5858 26.75 20 26.75C20.4142 26.75 20.75 26.4142 20.75 26V25.7361C21.2548 25.6797 21.7284 25.4536 22.091 25.091C22.5129 24.669 22.75 24.0967 22.75 23.5C22.75 22.9033 22.5129 22.331 22.091 21.909C21.669 21.4871 21.0967 21.25 20.5 21.25H19.5C19.3011 21.25 19.1103 21.171 18.9697 21.0303C18.829 20.8897 18.75 20.6989 18.75 20.5C18.75 20.3011 18.829 20.1103 18.9697 19.9697C19.1103 19.829 19.3011 19.75 19.5 19.75H22C22.4142 19.75 22.75 19.4142 22.75 19C22.75 18.5858 22.4142 18.25 22 18.25H20.75Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.25C14.2707 10.25 13.5712 10.5397 13.0555 11.0555C12.5397 11.5712 12.25 12.2707 12.25 13V27C12.25 27.7293 12.5397 28.4288 13.0555 28.9445C13.5712 29.4603 14.2707 29.75 15 29.75H25C25.7293 29.75 26.4288 29.4603 26.9445 28.9445C27.4603 28.4288 27.75 27.7293 27.75 27V16C27.75 15.8011 27.671 15.6103 27.5303 15.4697L22.5303 10.4697C22.3897 10.329 22.1989 10.25 22 10.25H15ZM14.1161 12.1161C14.3505 11.8817 14.6685 11.75 15 11.75H21.25V14.9999C21.25 15.464 21.4344 15.9092 21.7626 16.2373C22.0908 16.5655 22.5359 16.7499 23 16.7499H26.25V27C26.25 27.3315 26.1183 27.6495 25.8839 27.8839C25.6495 28.1183 25.3315 28.25 25 28.25H15C14.6685 28.25 14.3505 28.1183 14.1161 27.8839C13.8817 27.6495 13.75 27.3315 13.75 27V13C13.75 12.6685 13.8817 12.3505 14.1161 12.1161ZM25.1893 15.2499L22.75 12.8107V14.9999C22.75 15.0662 22.7763 15.1298 22.8232 15.1767C22.8701 15.2236 22.9337 15.2499 23 15.2499H25.1893Z" fill="currentColor"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>



</template>

<script>
export default {
  name: "IconBadgeContact"
}
</script>
