<template>
<svg width="137" height="131" viewBox="0 0 137 131" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M136.791 65.5L121.643 48.1788L123.754 25.27L101.342 20.1792L89.6081 0.3125L68.4998 9.37667L47.3915 0.3125L35.6577 20.1171L13.2456 25.1458L15.3565 48.1167L0.208145 65.5L15.3565 82.8213L13.2456 105.792L35.6577 110.883L47.3915 130.688L68.4998 121.561L89.6081 130.625L101.342 110.821L123.754 105.73L121.643 82.8213L136.791 65.5ZM52.234 90.3954L37.4581 75.4954C35.0369 73.0742 35.0369 69.1629 37.4581 66.7417L37.8927 66.3071C40.314 63.8858 44.2873 63.8858 46.7086 66.3071L56.704 76.3646L88.6769 44.3296C91.0981 41.9083 95.0715 41.9083 97.4927 44.3296L97.9273 44.7642C100.349 47.1854 100.349 51.0967 97.9273 53.5179L61.174 90.3954C58.6286 92.8167 54.7173 92.8167 52.234 90.3954Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: "IconCheck"
}
</script>
