import { mapActions, mapGetters } from "vuex"
import checkCookieMixin from "@/mixins/checkCookie"
import { convertDictionaryToObject } from "@/utils/appHelpers"
import { getLanguageFilesContents, getDictonaryFromObject } from "../languages/index"
// Get the app dictionary and convert it to object
const appDictionary = convertDictionaryToObject()

import Vue from "vue"
import smileUi from "@alegradev/smile-ui"
import store from "@/store/index"

Vue.use(smileUi)

const bootFiles = require.context("@/boot", false, /\.js$/) // https://webpack.js.org/guides/dependency-management/#requirecontext
bootFiles.keys().forEach(modulePath => {
  const module = bootFiles(modulePath)
  module.default({ Vue, store }) // default export from module
})

export default {
  store,
  mixins: [checkCookieMixin],
  data() {
    return {
      appDictionary: appDictionary,
      loading: true,
      tries: 0
    }
  },
  created() {
    // Insertamos la fuente roboto en local para probar el WC en cualquier archivo.
    // En producción/testing al instalar el WC tomara la fuente roboto del microfront o monolítico.
    const appEnviroment = String(process.env.VUE_APP_ENVIROMENT).toLowerCase()
    if (appEnviroment === "local") {
      const linkNode = document.createElement("link")
      linkNode.type = "text/css"
      linkNode.rel = "stylesheet"
      linkNode.href =
        "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap"
      document.head.appendChild(linkNode)
    }
  },
  computed: {
    ...mapGetters({
      appVersion: "app/appVersion",
      userLenguage: "app/userLenguage",
      isLoading: "config/loading",
      error: "config/error",
      $hasUser: "session/$hasUser"
    })
  },
  watch: {
    isLoading(value) {
      this.loading = value
    }
  },
  async mounted() {
    if (!this.$hasUser) {

    const appEnviroment = String(process.env.VUE_APP_ENVIROMENT).toLowerCase()
    if (appEnviroment === "local") {
      await this.loadUserInfo()
    }else {
      window.addEventListener("load-user-self", e => {
        this.loadUserInfo(e.detail)
      })
      // Emitimos el evento para que el WC pueda cargar
      // el usuario en caso que el WC cargara despues de alegra commons

      if (window._smileLayout && window._smileLayout.eventLoadUserSelf) {
        await this.loadUserInfo(window._smileLayout.eventLoadUserSelf.detail)
      }
    }

    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      addToDictionary: "app/addToDictionary",
      getUser: "session/getUser",
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async loadToDictionary() {
      try {
        this.setError(false)
        const userLenguage = this.userLenguage
        const appVersion = this.appVersion
        const langFilesMerged = getLanguageFilesContents(userLenguage, appVersion)
        await this.addToDictionary(langFilesMerged)
        const appDictionaryMerged = getDictonaryFromObject(
          this.appDictionary,
          userLenguage,
          appVersion
        )
        await this.addToDictionary(appDictionaryMerged)
      } catch (e) {
        this.setError(true)
        // console.error(e)
      }
    },
    async loadUserInfo(userSelf) {
      if (this.isLoading) return
      try {
        this.setError(false)
        this.setLoading(true)
        await this.getUser(userSelf)
        await this.loadToDictionary()
      } catch (error) {
        this.setError(true)
      } finally {
        this.setLoading(false)
      }
    }
  }
}
