// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-3!../../node_modules/@alegradev/smile-ui/dist/smile-ui.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-3!../../node_modules/@alegradev/smile-ui-alegra/dist/smile-ui-alegra.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Mulish);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Inter|Inter:400,600,800);"]);
exports.push([module.id, "@import url(https://rsms.me/inter/inter.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".layout-default{font-family:Inter,sans-serif!important;color:#707070}.skeleton-new{margin-bottom:10px;margin:0 auto;position:relative;width:1024px;height:235px;background-color:#c2cfd6;border-radius:5px;animation:skeleton-new-loading 1.6s infinite}@keyframes skeleton-new-loading{0%{background-color:#ededed}50%{background-color:#f0f3f5}to{background-color:#ededed}}", ""]);
// Exports
module.exports = exports;
