import axios from "axios"
import {
  baseHubspotApiUrl,
  hubspotApiToken,
  baseRecordsApiUrl,
  cookieAuthToken
} from "@/utils/appHelpers"

export const getCompanyAttributionRequest = email => {
  const request = axios.get(`${baseHubspotApiUrl}/company-attribution?email=${email}`, {
    headers: {
      Authorization: `Bearer ${hubspotApiToken}`
    }
  })

  // request.catch(error => {
  //   console.log(error)
  // });

  return request
}

export const getRecordsPropertyRequest = property => {
  const request = axios.get(`${baseRecordsApiUrl}/company/${property}`, {
    headers: {
      Authorization: `Bearer ${cookieAuthToken}`
    }
  })

  // request.catch(error => {
  //   console.log(error)
  // });

  return request
}
