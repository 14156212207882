import es_AR from "./es_AR"
import es_CL from "./es_CL"
import es_CO from "./es_CO"
import es_CR from "./es_CR"
import es_DO from "./es_DO"
import es_ES from "./es_ES"
import es_KE from "./es_KE"
import es_MX from "./es_MX"
import es_NG from "./es_NG"
import es_PA from "./es_PA"
import es_PE from "./es_PE"
import es_US from "./es_US"
import es_ZA from "./es_ZA"

const langs = {
  es_AR,
  es_CL,
  es_CO,
  es_CR,
  es_DO,
  es_ES,
  es_KE,
  es_MX,
  es_NG,
  es_PA,
  es_PE,
  es_US,
  es_ZA
}

export default langs
