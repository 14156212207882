<template>
  <div v-if="!loading" class="first__steps__mexico__resico">
    <HaveRegime v-if="company.regime != 'Sin régimen'" :user="company" :invoice="invoice" />
    <NotHaveRegime v-else :user="company" :invoice="invoice" />
  </div>
</template>

<script>
import HaveRegime from "@/components/mexico/resico/HaveRegime"
import NotHaveRegime from "@/components/mexico/resico/NotHaveRegime"
import { mapGetters, mapActions } from "vuex"
import { request } from "@alegradev/smile-ui-alegra"
import { baseApiUrl } from '@/utils/appHelpers'

export default {
  components: {
    HaveRegime,
    NotHaveRegime
  },
  data: () => ({
    invoice: false,
    loading: true
  }),
  computed: {
    ...mapGetters({
      company: "session/company"
    })
  },
  async mounted() {
    await this.init()
  },
  methods: {
    ...mapActions({
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async init() {
      this.loading = true
      try {
        const respInvoices = await request({
          url: `${baseApiUrl}v1/invoices?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        });
        this.invoice = respInvoices.data.length > 0 ? true : false
        this.loadingRequest = false
      } catch (error) {
        this.setError(true)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
