var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"resico"},[_c('sm-icon',{staticClass:"resico__close",attrs:{"icon":"close","size":"medium"},on:{"click":function($event){_vm.show = !_vm.show}}}),_c('div',{staticClass:"resico__container"},[_c('p',{staticClass:"resico__title"},[_vm._v("¡Recibe los beneficios del RESICO!")]),_c('p',{staticClass:"resico__subtitle"},[_vm._v(" Descubre cómo implementar este nuevo régimen y empieza a facturar fácil con Alegra. ")]),_c('div',{staticClass:"resico__steps"},[_c('div',{staticClass:"resico__step"},[_vm._m(0),_c('p',{staticClass:"resico__step__title resico__step__title__completed"},[_vm._v("Crea tu cuenta")]),_c('small',{staticClass:"resico__step__subtitle resico__step__completed"},[_vm._v("Completado")]),_c('div',{staticClass:"resico__step__link__progress",class:{ completed: _vm.step > 0 }})]),_c('div',{staticClass:"resico__step"},[_c('div',{staticClass:"resico__icon",class:{
            resico__icon__in__progress: _vm.step == 1,
            resico__icon__no__completed: _vm.step < 1
          }},[(_vm.step == 1)?_c('img',{attrs:{"src":require("@/assets/Icon-beneficios-resico-in-progress.svg"),"alt":"alegra"}}):(_vm.step >= 2 || _vm.knowResicoView)?_c('img',{attrs:{"src":require("@/assets/Icon-beneficios-resico-completed.svg"),"alt":"alegra"}}):_c('img',{attrs:{"src":require("@/assets/Icon-beneficios-resico-no-completed.svg"),"alt":"alegra"}})]),_c('p',{staticClass:"resico__step__title"},[_vm._v("Conoce los beneficios RESICO")]),_c('small',{staticClass:"resico__step__subtitle"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){return _vm.redirect('url-conoce-resico')}}},[_vm._v("Empezar")])]),_c('div',{staticClass:"resico__step__link__progress resico__step__link__progress__step2",class:{ completed: _vm.step > 1 }})]),_c('div',{staticClass:"resico__step"},[_c('div',{staticClass:"resico__icon",class:{
            resico__icon__in__progress: _vm.step == 2,
            resico__icon__no__completed: _vm.step < 2 && !_vm.activateResicoView
          }},[(_vm.step == 2)?_c('img',{attrs:{"src":require("@/assets/Icon-resico-in-progress.svg"),"alt":"alegra"}}):(_vm.step >= 3 || _vm.activateResicoView)?_c('img',{attrs:{"src":require("@/assets/Icon-resico-completed.svg"),"alt":"alegra"}}):_c('img',{attrs:{"src":require("@/assets/Icon-resico-no-completed.svg"),"alt":"alegra"}})]),_c('p',{staticClass:"resico__step__title"},[_vm._v("Actívate en el RESICO")]),_c('small',{staticClass:"resico__step__subtitle"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){return _vm.redirect('url-activate-resico')}}},[_vm._v("Empezar")])]),_c('div',{staticClass:"resico__step__link__progress resico__step__link__progress__step3",class:{ completed: _vm.step > 2 }})]),_c('div',{staticClass:"resico__step"},[_c('div',{staticClass:"resico__icon",class:{
            resico__icon__in__progress: _vm.step == 3,
            resico__icon__no__completed: _vm.step < 3 && !_vm.invoice
          }},[(_vm.step == 3)?_c('img',{attrs:{"src":require("@/assets/Icon-crear-primera-factura-in-progress.svg"),"alt":"alegra"}}):(_vm.step >= 4 || _vm.invoice)?_c('img',{attrs:{"src":require("@/assets/Icon-crear-primera-factura-completed.svg"),"alt":"alegra"}}):_c('img',{attrs:{"src":require("@/assets/Icon-crear-primera-factura-no-completed.svg"),"alt":"alegra"}})]),_c('p',{staticClass:"resico__step__title"},[_vm._v("Crea tu primera factura")]),_vm._m(1)])])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resico__icon"},[_c('img',{attrs:{"src":require("@/assets/new-user-in-progress.svg"),"alt":"alegra"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"resico__step__subtitle"},[_c('a',{attrs:{"href":"/invoice/add"}},[_vm._v("Empezar")])])
}]

export { render, staticRenderFns }