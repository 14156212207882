<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.25C14.3766 10.25 12.25 12.3766 12.25 15C12.25 17.6234 14.3766 19.75 17 19.75C19.6234 19.75 21.75 17.6234 21.75 15C21.75 12.3766 19.6234 10.25 17 10.25ZM13.75 15C13.75 13.2051 15.2051 11.75 17 11.75C18.7949 11.75 20.25 13.2051 20.25 15C20.25 16.7949 18.7949 18.25 17 18.25C15.2051 18.25 13.75 16.7949 13.75 15Z" fill="currentColor"/>
  <path d="M12.7019 24.7019C13.3114 24.0924 14.138 23.75 15 23.75H19C19.862 23.75 20.6886 24.0924 21.2981 24.7019C21.9076 25.3114 22.25 26.138 22.25 27V29C22.25 29.4142 22.5858 29.75 23 29.75C23.4142 29.75 23.75 29.4142 23.75 29V27C23.75 25.7402 23.2496 24.532 22.3588 23.6412C21.468 22.7504 20.2598 22.25 19 22.25H15C13.7402 22.25 12.532 22.7504 11.6412 23.6412C10.7504 24.532 10.25 25.7402 10.25 27V29C10.25 29.4142 10.5858 29.75 11 29.75C11.4142 29.75 11.75 29.4142 11.75 29V27C11.75 26.138 12.0924 25.3114 12.7019 24.7019Z" fill="currentColor"/>
  <path d="M23.2734 10.9439C23.3762 10.5426 23.7848 10.3006 24.186 10.4033C25.2078 10.6649 26.1134 11.2592 26.7601 12.0923C27.4068 12.9255 27.7578 13.9502 27.7578 15.0049C27.7578 16.0596 27.4068 17.0843 26.7601 17.9175C26.1134 18.7506 25.2078 19.3448 24.186 19.6064C23.7848 19.7092 23.3762 19.4672 23.2734 19.0659C23.1707 18.6646 23.4127 18.2561 23.814 18.1533C24.5131 17.9743 25.1327 17.5678 25.5752 16.9977C26.0177 16.4276 26.2578 15.7265 26.2578 15.0049C26.2578 14.2832 26.0177 13.5821 25.5752 13.0121C25.1327 12.442 24.5131 12.0354 23.814 11.8564C23.4127 11.7537 23.1707 11.3451 23.2734 10.9439Z" fill="currentColor"/>
  <path d="M26.1875 22.4237C25.7864 22.3202 25.3774 22.5614 25.2738 22.9624C25.1703 23.3635 25.4115 23.7725 25.8125 23.8761C26.5071 24.0554 27.1228 24.4595 27.5637 25.0253C28.0041 25.5906 28.2454 26.2856 28.25 27.0022V28.9999C28.25 29.4141 28.5858 29.7499 29 29.7499C29.4142 29.7499 29.75 29.4141 29.75 28.9999L29.75 26.9999L29.75 26.9956C29.744 25.9472 29.3913 24.9303 28.7469 24.1033C28.1025 23.2763 27.2026 22.6858 26.1875 22.4237Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>



</template>

<script>
export default {
  name: "IconBadgeItem"
}
</script>
