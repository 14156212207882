<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path d="M14.75 12C14.75 11.6967 14.5673 11.4232 14.287 11.3071C14.0068 11.191 13.6842 11.2552 13.4697 11.4697L11.4697 13.4697C11.1768 13.7626 11.1768 14.2374 11.4697 14.5303C11.7626 14.8232 12.2374 14.8232 12.5303 14.5303L13.25 13.8107V18C13.25 18.4142 13.5858 18.75 14 18.75C14.4142 18.75 14.75 18.4142 14.75 18V12Z" fill="currentColor"/>
  <path d="M18.25 14C18.25 13.5858 18.5858 13.25 19 13.25H28C28.4142 13.25 28.75 13.5858 28.75 14C28.75 14.4142 28.4142 14.75 28 14.75H19C18.5858 14.75 18.25 14.4142 18.25 14Z" fill="currentColor"/>
  <path d="M18.25 20C18.25 19.5858 18.5858 19.25 19 19.25H28C28.4142 19.25 28.75 19.5858 28.75 20C28.75 20.4142 28.4142 20.75 28 20.75H19C18.5858 20.75 18.25 20.4142 18.25 20Z" fill="currentColor"/>
  <path d="M20 25.25C19.5858 25.25 19.25 25.5858 19.25 26C19.25 26.4142 19.5858 26.75 20 26.75H28C28.4142 26.75 28.75 26.4142 28.75 26C28.75 25.5858 28.4142 25.25 28 25.25H20Z" fill="currentColor"/>
  <path d="M14 22.75C13.6685 22.75 13.3505 22.8817 13.1161 23.1161C12.8817 23.3505 12.75 23.6685 12.75 24C12.75 24.4142 12.4142 24.75 12 24.75C11.5858 24.75 11.25 24.4142 11.25 24C11.25 23.2707 11.5397 22.5712 12.0555 22.0555C12.5712 21.5397 13.2707 21.25 14 21.25C14.7294 21.25 15.4288 21.5397 15.9446 22.0555C16.4603 22.5712 16.75 23.2707 16.75 24C16.75 24.5277 16.5204 24.9381 16.2772 25.2475C16.1008 25.472 15.8712 25.6964 15.678 25.885C15.6255 25.9363 15.5757 25.985 15.5303 26.0303C15.5143 26.0464 15.4976 26.0617 15.4801 26.0762L14.0715 27.25H16C16.4142 27.25 16.75 27.5858 16.75 28C16.75 28.4142 16.4142 28.75 16 28.75H12C11.6842 28.75 11.4023 28.5522 11.2948 28.2553C11.1873 27.9584 11.2773 27.626 11.5199 27.4238L14.494 24.9454C14.5621 24.8776 14.6245 24.8165 14.6821 24.76C14.857 24.5888 14.9877 24.4608 15.0978 24.3207C15.2296 24.1529 15.25 24.0633 15.25 24C15.25 23.6685 15.1183 23.3505 14.8839 23.1161C14.6495 22.8817 14.3315 22.75 14 22.75Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>



</template>

<script>
export default {
  name: "IconBadgeItem"
}
</script>
