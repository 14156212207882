<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 11.75C14.6685 11.75 14.3505 11.8817 14.1161 12.1161C13.8817 12.3505 13.75 12.6685 13.75 13V27C13.75 27.3315 13.8817 27.6495 14.1161 27.8839C14.3505 28.1183 14.6685 28.25 15 28.25H25C25.3315 28.25 25.6495 28.1183 25.8839 27.8839C26.1183 27.6495 26.25 27.3315 26.25 27V16.75H23C22.5359 16.75 22.0908 16.5656 21.7626 16.2374C21.4344 15.9092 21.25 15.4641 21.25 15V11.75H15ZM22.75 12.8107L25.1893 15.25H23C22.9337 15.25 22.8701 15.2237 22.8232 15.1768C22.7763 15.1299 22.75 15.0663 22.75 15V12.8107ZM13.0555 11.0555C13.5712 10.5397 14.2707 10.25 15 10.25H22C22.1989 10.25 22.3897 10.329 22.5303 10.4697L27.5303 15.4697C27.671 15.6103 27.75 15.8011 27.75 16V27C27.75 27.7293 27.4603 28.4288 26.9445 28.9445C26.4288 29.4603 25.7293 29.75 25 29.75H15C14.2707 29.75 13.5712 29.4603 13.0555 28.9445C12.5397 28.4288 12.25 27.7293 12.25 27V13C12.25 12.2707 12.5397 11.5712 13.0555 11.0555ZM23.5303 20.4697C23.8232 20.7626 23.8232 21.2374 23.5303 21.5303L19.5303 25.5303C19.2374 25.8232 18.7626 25.8232 18.4697 25.5303L16.4697 23.5303C16.1768 23.2374 16.1768 22.7626 16.4697 22.4697C16.7626 22.1768 17.2374 22.1768 17.5303 22.4697L19 23.9393L22.4697 20.4697C22.7626 20.1768 23.2374 20.1768 23.5303 20.4697Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBadgeTbai"
}
</script>
