import { render, staticRenderFns } from "./AppFirstSteps.vue?vue&type=template&id=2ab020e2&scoped=true&"
import script from "./AppFirstSteps.vue?vue&type=script&lang=js&"
export * from "./AppFirstSteps.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2ab020e2",
  null
  ,true
)

export default component.exports