<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8042 14.6348L20 20.0986L28.1958 14.6347C28.0396 14.1226 27.5633 13.75 27 13.75H13C12.4367 13.75 11.9604 14.1226 11.8042 14.6348ZM28.25 16.4014L20.416 21.624C20.1641 21.792 19.8359 21.792 19.584 21.624L11.75 16.4014V25C11.75 25.6904 12.3096 26.25 13 26.25H27C27.6904 26.25 28.25 25.6904 28.25 25V16.4014ZM10.25 15C10.25 13.4812 11.4812 12.25 13 12.25H27C28.5188 12.25 29.75 13.4812 29.75 15V25C29.75 26.5188 28.5188 27.75 27 27.75H13C11.4812 27.75 10.25 26.5188 10.25 25V15Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMail"
}
</script>
