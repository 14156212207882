function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * It triggers a GTM event with new taxonomy with the given name, and the given data
 * @param eventName - The name of the event you want to trigger.
 * @param data - the data object that is passed to the function.
 * @param additional - This is an object that you can use to pass additional data to the GTM event.
 * @param _idProduct - The product number if it is required to send manually
 * @param experimentId - Object of experiment in case it is one. Example: experimentId: { idExperiment: "A" or "B" }
 */
export const triggerGTMEventNewTaxonomy = (
  eventName,
  { data = null, user = null, additional = null, experimentId = null }
) => {
  let companyProperties = {};

  if (data && user) {
    companyProperties = {
      idCompany: data.id,
      uuid: data.uuid,
      email: user.email,
      appVersion: data.applicationVersion,
      planName: user.membership.planName,
      isPaying: user.membership.isPaid,
      regime: data.regime,
      sector: data.sector,
      parity: (data.id % 2) === 0 ? "even" : "odd",
      profile: data.profile,
      role: user.role,
      employeesNumber: data.employeesNumber,
      ...(experimentId && { experimentId })
    };
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      companyProperties: data && user ? companyProperties : null,
      eventProperties: {
        idProduct: "1",
        productKey: "alegra-web",
        eventTimestamp: formatDate(new Date()),
        ...(additional && additional)
      }
    });
  } else if (process.env.VUE_APP_ENV != "development") {
    throw new Error(
      "Could not read the object window.dataLayer from analytics GTM script."
    );
  }
};
