<template>
  <div>
    <component
      :is="firstStepsComponent"
      v-if="firstStepsComponent && !loadingBySearch && !loadingAccountantWizardCore"
    />
    <div v-else>
      <div class="skeleton-new" />
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { firstStepsCookieBaseName } from "@/utils/appHelpers"
import { mapGetters, mapActions } from "vuex"
import { getCompanyAttributionRequest, getRecordsPropertyRequest } from "@/modules/requests"

import FirstStepsArgentina from "@/components/argentina/Index.vue"
import FirstStepsChile from "@/components/chile/Index.vue"
import FirstStepsColombia from "@/components/colombia/Index.vue"
import FirstStepsCostaRica from "@/components/costaRica/Index.vue"
import FirstStepsDom from "@/components/republicaDominicana/Index.vue"
import FirstStepsInt from "@/components/other/Index.vue"
import FirstStepsMex from "@/components/mexico/Index.vue"
import FirstStepsPanama from "@/components/panama/Index.vue"
import FirstStepsPeru from "@/components/peru/Index.vue"
import FirstStepsSpain from "@/components/spain/Index.vue"
import FirstStepsUsa from "@/components/usa/Index.vue"

import AccountantCOL from "@/components/profile/accountant/AccountantCOL.vue"
import AccountantCRI from "@/components/profile/accountant/AccountantCRI.vue"
import AccountantMEX from "@/components/profile/accountant/AccountantMEX.vue"
import AccountantDEFAULT from "@/components/profile/accountant/AccountantDEFAULT.vue"

import FirstStepsResicoMex from "@/components/mexico/resico/Index.vue"
// import FirstStepsStudents from "@/components/students/Index.vue"
import FirstStepsOnlyInvoicing from "@/components/only-invoicing/Index.vue"
import FirstStepsBySearchMex from "@/components/bySearch/mexico/Index.vue"
import FirstStepsBySearchDom from "@/components/bySearch/republicaDominicana/Index.vue"

import FirstStepsByProfileInvoicing from "@/components/byProfile/IndexInvoicing.vue"
import FirstStepsByProfileIncomesExpenses from "@/components/byProfile/IndexIncomesExpenses.vue"
import FirstStepsByProfileInventory from "@/components/byProfile/IndexInventory.vue"

export default {
  data: () => ({
    loadingBySearch: true,
    showFirstStepsBySearch: false,
    loadingAccountantWizardCore: true,
    accountantWizardCore: false
  }),

  computed: {
    ...mapGetters({
      appVersion: "app/appVersion",
      company: "session/company",
      user: "session/user"
    }),
    firstStepsByProfile() {
      return Cookies.get(`${firstStepsCookieBaseName}-profile-${this.company.id}`)
    },
    firstStepsHealth() {
      return Cookies.get(`${firstStepsCookieBaseName}-health-${this.company.id}`)
    },
    firstStepsComponent() {
      if (
        this.company.profile == "accountant" ||
        this.company.profile == "student" ||
        this.company.origin == "educacion" ||
        this.accountantWizardCore
      ) {
        if (this.appVersion == "colombia") {
          return AccountantCOL
        } else if (this.appVersion == "costaRica") {
          return AccountantCRI
        } else if (this.appVersion == "mexico") {
          return AccountantMEX
        } else {
          return AccountantDEFAULT
        }
      }

      // if (this.company.profile == "student" || this.company.origin == "educacion") {
      //   return FirstStepsStudents
      // }

      if (this.appVersion == "argentina") {
        return FirstStepsArgentina
      }

      if (this.appVersion == "chile") {
        return FirstStepsChile
      }

      if (this.appVersion == "colombia") {
        if (this.company.origin == "alegra-only-invoicing" && this.firstStepsHealth) {
          return FirstStepsOnlyInvoicing
        }

        return FirstStepsColombia
      }

      if (this.appVersion == "costaRica") {
        return FirstStepsCostaRica
      }

      if (this.appVersion == "mexico") {
        if (this.showFirstStepsBySearch) {
          return FirstStepsBySearchMex
        } else if (this.company.origin == "alegra-resico") {
          return FirstStepsResicoMex
        }

        return FirstStepsMex
      }

      if (this.appVersion == "panama") {
        if (this.firstStepsByProfile) {
          switch (this.firstStepsByProfile) {
            case "incomeAndExpenses":
              return FirstStepsByProfileIncomesExpenses
            case "inventory":
              return FirstStepsByProfileInventory
            default:
              return FirstStepsByProfileInvoicing
          }
        }

        return FirstStepsPanama
      }

      if (this.appVersion == "peru") {
        return FirstStepsPeru
      }

      if (this.appVersion == "republicaDominicana") {
        if (this.showFirstStepsBySearch) {
          return FirstStepsBySearchDom
        } else if (this.firstStepsByProfile) {
          switch (this.firstStepsByProfile) {
            case "incomeAndExpenses":
              return FirstStepsByProfileIncomesExpenses
            case "inventory":
              return FirstStepsByProfileInventory
            default:
              return FirstStepsByProfileInvoicing
          }
        }

        return FirstStepsDom
      }

      if (this.appVersion == "spain") {
        return FirstStepsSpain
      }

      if (this.appVersion == "usa") {
        return FirstStepsUsa
      }

      return FirstStepsInt
    }
  },
  methods: {
    ...mapActions({
      setHideEcStep: "config/setHideEcStep",
    }),
    async getCompanyAttribution(email) {
      try {
        const { data } = await getCompanyAttributionRequest(email.replaceAll("+", "%2B"))
        let results = data.body.data
        let { hs_analytics_source, hs_analytics_source_data_2 } = results

        let originalSources = ["ORGANIC_SEARCH", "PAID_SEARCH"]

        if (originalSources.includes(hs_analytics_source)) {
          if (
            hs_analytics_source_data_2.includes("facturacion") ||
            hs_analytics_source_data_2.includes("facturación")
          ) {
            this.showFirstStepsBySearch = true
          }
        }
      } finally {
        this.loadingBySearch = false
      }
    },
    async getSignUpRole() {
      const validRoles = ["accountant", "accountant_assistant"]
      try {
        const { data } = await getRecordsPropertyRequest("signUp_Role")
        if (data.key && validRoles.includes(data.value)) {
          this.setHideEcStep(true)
          this.accountantWizardCore = true
        }
      } finally {
        this.loadingAccountantWizardCore = false
      }
    }
  },
  async created() {
    if (this.appVersion == "mexico" || this.appVersion == "republicaDominicana") {
      this.getCompanyAttribution(this.user.email)
    } else {
      this.loadingBySearch = false
    }

    /*
     * WIZARD CORE (first steps accountant for entrepreneur profile)
     * If the 'signUp_Role' property in the records micro has the value
     * 'accountant' or 'accountant_assistant', the 'accountantWizardCore' property
     * is set to true to display the first steps of the accountant.
     */
    const wizardCoreCountries = ["republicaDominicana"]
    if (wizardCoreCountries.includes(this.appVersion) && this.company.profile == "entrepreneur") {
      await this.getSignUpRole()
    } else {
      // If the version is not in the wizard core array, we automatically set the loading to ''false'
      // so that the component loads
      this.loadingAccountantWizardCore = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
