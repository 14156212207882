<template>
  <div v-if="!loading && show">
    <NewFirstStepsComponent :achievements="achievements" :skipModal="skipModal" onlyByProfile />
  </div>
  <div v-else>
    <div v-show="show" class="skeleton-new" />
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { isBefore } from "date-fns"
import NewFirstStepsComponent from "@/components/NewFirstStepsComponent.vue"
import { mapGetters, mapActions } from "vuex"
import { request } from "@alegradev/smile-ui-alegra"
import { baseApiUrl, firstStepsCookieBaseName, checkAllStepsCompleted } from "@/utils/appHelpers"

export default {
  components: {
    NewFirstStepsComponent
  },
  data: () => ({
    loading: true,
    show: true,
    items: false,
    warehouse: false,
    adjustments: false,
    priceList: false,
    achievements: [
      {
        id: "badge-client",
        title: "byProfile.inventory.product.title",
        actions: ["start"],
        link: "/item/add",
        stepName: "create product"
      },
      {
        id: "badge-product",
        title: "byProfile.inventory.warehouse.title",
        actions: ["start"],
        link: "/warehouse/add",
        stepName: "warehouses"
      },
      {
        id: "badge-invoice",
        title: "byProfile.inventory.adjustments.title",
        actions: ["start"],
        link: "/inventory-adjustment/add",
        stepName: "inventory adjustments"
      },
      {
        id: "badge-config-alt",
        title: "byProfile.inventory.priceList.title",
        actions: ["start"],
        link: "/price-list/add",
        stepName: "price lists"
      }
    ],
    skipModal: {
      title: "",
      text: "",
      help: ""
    }
  }),
  computed: {
    ...mapGetters({
      company: "session/company",
      user: "session/user"
    }),

    notShowAgainCookieName() {
      return `${firstStepsCookieBaseName}-no-show-${this.company.id}`
    }
  },
  async mounted() {
    if (Cookies.get(this.notShowAgainCookieName)) {
      this.loading = false
      this.show = false
      return
    }

    await this.init()
    this.setInfo()
  },
  methods: {
    ...mapActions({
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async init() {
      this.loading = true

      try {
        const respItems = await request({
          url: `${baseApiUrl}v1/items?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.items = respItems.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respWarehouses = await request({
          url: `${baseApiUrl}v1/warehouses?start=0&limit=2`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.warehouse = respWarehouses.data.length > 1 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respAdjustments = await request({
          url: `${baseApiUrl}v1/inventory-adjustments?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.adjustments = respAdjustments.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respPriceList = await request({
          url: `${baseApiUrl}v1/price-lists?limit=2`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.priceList = respPriceList.data.length > 1 ? true : false
      } catch (error) {
        this.setError(true)
      }

      //this.loading = false
    },

    getIndex(id) {
      return this.achievements.findIndex(achievement => achievement.id === id)
    },

    setInfo() {
      let items = this.getIndex("badge-client")
      let warehouse = this.getIndex("badge-product")
      let adjustments = this.getIndex("badge-invoice")
      let priceList = this.getIndex("badge-config-alt")

      if (items !== -1 && this.items) {
        this.achievements[items].actions = ["completed"]
      }

      if (warehouse !== -1 && this.warehouse) {
        this.achievements[warehouse].actions = ["completed"]
      }

      if (adjustments !== -1 && this.adjustments) {
        this.achievements[adjustments].actions = ["completed"]
      }

      if (priceList !== -1 && this.priceList) {
        this.achievements[priceList].actions = ["completed"]
      }

      if (
        !Cookies.get(this.notShowAgainCookieName) &&
        checkAllStepsCompleted(this.achievements) &&
        isBefore(new Date(this.company.registryDate.split(" ")[0]), new Date("2023/01/09"))
      ) {
        Cookies.set(this.notShowAgainCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })

        this.show = false
      }

      this.loading = false
    }
  }
}
</script>
