import Cookies from "js-cookie"
import { getEnv } from "@/utils/appHelpers"

export default {
  mounted() {
    const appEnviroment = String(process.env.VUE_APP_ENVIROMENT).toLowerCase()
    const cookieTokenKey = getEnv("COOKIE_TOKEN")
    const that = this

    // Checks if the cookie or the localStorage value is present, only for testing or local
    if (appEnviroment === "local" || appEnviroment === "testing") {
      // Conditions for both local and testing
      const conditions = [!Cookies.get(cookieTokenKey), !localStorage.getItem(cookieTokenKey)]
      // also, if it is on local, check if it has the token setted on .env
      if (appEnviroment === "local") {
        const hasTokenSettedInDotEnv = !getEnv("VUE_APP_AUTH_HEADER_TOKEN_DEV")
        conditions.push(hasTokenSettedInDotEnv)
      }

      if (conditions.every(Boolean))
        setTimeout(() => {
          that.$smAlertWar(
            `No existe la cookie de sesión ${cookieTokenKey}. Aseguresé de estar logueado en hopper o de seleccionar una sesión desde el menu superior derecho.`
          )
        }, 2000)
    }
  }
}
