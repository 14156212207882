<template>
  <transition name="fade-out">
    <div v-if="show">
      <div class="container">
        <sm-icon class="close" icon="close" size="medium" @click="showCloseModal = true" />

        <div class="container-texts">
          <div class="title">
            {{ title }}
          </div>
          <div class="subtitle">
            {{ subtitle }}
          </div>
        </div>

        <div class="container-main">

          <div ref="container-carousel" class="container-carousel">
            <div class="carousel">

              <div
                v-for="(achievement, idx) in achievementsArray"
                v-bind:key="`${achievement.id}-${idx}`"
                :id="achievement.id"
                :class="`achievement mx-auto ${isCompleted(achievement) ? 'completed' : ''}`"
              >
                <IconStepCompleted :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="getStepStatus(achievement, idx) === 'completed'" />
                <template v-else>
                  <IconBadgeFe :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-fe'" />
                  <IconBadgeFv :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-fv'" />
                  <IconBadgeItem :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-item' || achievement.id == 'badge-resolution' || achievement.id == 'badge-config-alt' || achievement.id == 'badge-item-numbering'" />
                  <IconBadgeContact :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-contact' || achievement.id == 'badge-user'" />
                  <IconBadgeAc :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-account-created'" />
                  <IconBadgeAcademy :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-academy'" />

                  <IconAccountingReports :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-accountingReports'" />
                  <IconBankConciliation :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-bankConciliator'" />
                  <IconChartAccounts :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-catalogAccounts'" />
                  <IconImports :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-accountingAdjustments'" />
                  <IconMail :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-electronicMailbox'" />
                  <IconMultiCompany :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-accountantSpace'" />
                  <IconTaxConciliation :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-taxConciliator'" />

                  <IconBadgeClient :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-client'" />
                  <IconBadgeInvoice :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-invoice'" />
                  <IconBadgeProduct :class="`achievement-icon ${getStepStatus(achievement, idx)}`" v-if="achievement.id == 'badge-product'" />
                </template>


                <h6 class="texts title" v-html="$transF(achievement.title)" />

                <div class="container-actions">
                  <div class="actions">
                    <a v-if="hasSkip(achievement)" href="#" @click.prevent="showSkipModal = true" style="margin-right: 10px; color: #334155;">
                      {{ $transF('skip') }}
                    </a>

                    <a v-if="getStepStatus(achievement, idx) === 'current' || getStepStatus(achievement, idx) === 'inProgress'" href="#!" @click="idsToRedirectAndComplete(achievement.id) ? redirect(achievement, isCompleted(achievement)) : redirectSteps(achievement)" class="current">
                      {{ mexAccounting
                        ? getMexAccountingStepStatus(achievement, idx)
                        : getStepStatus(achievement, idx) === 'current'
                        ? onlyAccountant
                          ? $transF('learn')
                          : $transF('begin')
                        : $transF('inProgress')
                      }}
                    </a>

                    <p v-else :class="`${getStepStatus(achievement, idx)}`">
                      {{ getAchievementTitle(getStepStatus(achievement, idx)) }}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div v-show="isAllCompleted" class="completed-container">
          <transition name="completed-slide" @after-enter="showCheck = true">
            <div v-if="showSlide" class="completed-slide" />
          </transition>
          <transition name="check-fade" @after-enter="show = false">
            <div v-if="showCheck" class="check-container">
              <IconCheck />
            </div>
          </transition>
        </div>

      </div>

      <div v-if="skipModal">
        <sm-modal v-if="showSkipModal" noPaddingHeader noPaddingBody noPaddingFooter width="425px" @close="showSkipModal = false">
          <template slot="header.actions"><div /></template>
          <template>
            <div class="modal-container">

              <div class="close-container">
                <sm-icon class="close" icon="close" size="medium" @click="showSkipModal = false" />
              </div>

              <div class="icon-badge">
                <img src="https://cdn1.alegra.com/images/icon-modal-skip-badge-fe.png">
              </div>

              <div class="modal-texts">
                <div class="title">{{ skipModal.title }}</div>
                <div class="text">{{ skipModal.text }}</div>
              </div>

              <div class="modal-button">
                <sm-button
                  label="Entendido"
                  size="medium"
                  primary
                  buttonClass="modal-button__small"
                  @click="closeSkipModal"
                />
              </div>

              <section class="alert-wrapper">
                <div class="alert-info">
                  <div class="alert-container-icon">
                    <sm-icon class="icon" icon="info-outline" />
                  </div>
                  <div class="alert-texts">
                    <p class="alert-title">Acerca de la facturacion electrónica</p>
                    <p class="alert-content">
                      Conoce todo lo que debes saber sobre este proceso
                      <a :href="skipModal.help" target="_blank">aquí</a>
                    </p>
                  </div>
                </div>
              </section>

            </div>
          </template>
        </sm-modal>
      </div>

      <sm-modal v-if="showCloseModal" noPaddingHeader noPaddingBody noPaddingFooter width="425px">
        <template slot="header.actions"><div /></template>
        <template>
          <div class="modal-container">
            <div class="modal-texts">
              <div class="title" style="margin-top: 10px">{{ $transF('youAreAboutToCloseThisSection') }}</div>
              <div class="text-close">
                {{ $transF('youAreAboutToCloseThisSectionDescription') }}
              </div>
            </div>

            <div class="modal-button" style="padding-bottom: 25px">
              <sm-button
                :label="$transF('cancel')"
                size="medium"
                buttonClass="modal-button__small mr-2"
                @click="cancelCloseModal"
              />
              <sm-button
                :label="$transF('confirm')"
                size="medium"
                primary
                buttonClass="modal-button__small"
                @click="closeModal"
              />
            </div>

            <div style="padding-bottom: 30px">
              <sm-checkbox
                :value="false"
                size="small"
                @click="changeNotShowAgain"
              >
                {{ $transF('noShowAgain') }}
              </sm-checkbox>
            </div>
          </div>
        </template>
      </sm-modal>

      <div v-if="showHappyWeekModal">
        <sm-modal v-if="showHappyWeekModal" noPaddingHeader noPaddingBody noPaddingFooter @close="closeHappyWeekModal">
          <template slot="header.actions"><div /></template>
          <template>
            <div class="modal-hw-container">

              <div class="close-hw-container">
                <sm-icon class="close" icon="close" size="medium" @click="closeHappyWeekModal" />
              </div>

              <div class="hw-background-container">
                <img :src="require(`@/assets/${happyWeekBg}.png`)">
              </div>

              <div class="hw-cta-container">
                <sm-button
                  label="Ver precios"
                  size="medium"
                  primary
                  buttonClass="hw-cta-container__button"
                  @click="goToProductsPlansHappyWeek"
                />
              </div>

              <div class="hw-footer">
                <a href="https://docs.google.com/document/d/e/2PACX-1vSwAYIwmwXUt_NuUmm-Ry4H8MOV14ke7LjVENT14xCj1WcGrCqZEb-XcpQES1PTWx4Mz7AeS02GU4VU/pub" target="_blank">*Aplican términos y condiciones</a>
              </div>

            </div>
          </template>
        </sm-modal>
      </div>
    </div>
  </transition>
</template>

<script>
import Cookies from "js-cookie"
import moment from "moment";
import { mapGetters } from "vuex"
import { DateTime, Settings } from "luxon";
import { firstStepsCookieBaseName, checkAllStepsCompleted, appEnviroment } from "@/utils/appHelpers"
import { triggerGTMEventNewTaxonomy } from "@/modules/analytics"
import ENVIROMENT from "../../enviroment.json"
// import axios from "axios";

import IconBadgeFe from "@/components/icons/IconBadgeFe.vue";
import IconBadgeFv from "@/components/icons/IconBadgeFv.vue";
import IconBadgeItem from "@/components/icons/IconBadgeItem.vue";
import IconBadgeContact from "@/components/icons/IconBadgeContact.vue";
import IconBadgeAc from "@/components/icons/IconBadgeAc.vue";
import IconBadgeAcademy from "@/components/icons/IconBadgeAcademy.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconStepCompleted from "@/components/icons/IconStepCompleted.vue";

import IconAccountingReports from "@/components/icons/accountant/IconAccountingReports.vue";
import IconBankConciliation from "@/components/icons/accountant/IconBankConciliation.vue";
import IconChartAccounts from "@/components/icons/accountant/IconChartAccounts.vue";
import IconImports from "@/components/icons/accountant/IconImports.vue";
import IconMail from "@/components/icons/accountant/IconMail.vue";
import IconMultiCompany from "@/components/icons/accountant/IconMultiCompany.vue";
import IconTaxConciliation from "@/components/icons/accountant/IconTaxConciliation.vue";

import IconBadgeClient from "@/components/icons/byProfile/IconBadgeClient.vue";
import IconBadgeInvoice from "@/components/icons/byProfile/IconBadgeInvoice.vue";
import IconBadgeProduct from "@/components/icons/byProfile/IconBadgeProduct.vue";

export default {
  name: "NewFirstStepsComponent",

  components: {
    IconBadgeFe,
    IconBadgeFv,
    IconBadgeItem,
    IconBadgeContact,
    IconBadgeAc,
    IconBadgeAcademy,
    IconCheck,
    IconStepCompleted,

    IconAccountingReports,
    IconBankConciliation,
    IconChartAccounts,
    IconImports,
    IconMail,
    IconMultiCompany,
    IconTaxConciliation,

    IconBadgeClient,
    IconBadgeInvoice,
    IconBadgeProduct
  },

  props: {
    achievements: {
      type: Array,
      required: true
    },
    skipModal: {
      type: Object,
      default: null
    },
    onlyInvoicing: {
      type: Boolean,
      default: false
    },
    onlyBySearch: {
      type: Boolean,
      default: false
    },
    onlyAccountant: {
      type: Boolean,
      default: false
    },
    onlyByProfile: {
      type: Boolean,
      default: false
    },
    mexAccounting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: true,
      showSkipModal: false,
      showCloseModal: false,
      showHappyWeekModal: false,
      notShowAgain: false,
      achievementsArray: this.achievements,
      stepEventTitle: {
        "badge-fe" : "electronic-invoicing-configured",
        "badge-fv" : "first-invoice-created",
        "badge-item" : "inventory-organized",
        "badge-contact" : "contact-managed",
        "badge-user" : "user-managed",
        "badge-resolution" : "numbering-configured",
      },
      isAllCompleted: false,
      showSlide: false,
      showCheck: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "session/company",
      user: "session/user",
      appVersion: "app/appVersion"
    }),

    title() {
      if (this.onlyInvoicing) {
        return "¡Realiza un recorrido rápido! 🚑"
      } else if (this.onlyBySearch) {
        if(this.appVersion == "mexico") {
          return "¡Crea tu primera factura! 📝"
        }
        return "¡Crea tu primera venta! 📝"
      } else if (this.onlyAccountant) {
        return this.$transF('titleAccountant')
      } else if (this.onlyByProfile) {
        switch (this.firstStepsByProfile) {
            case "incomeAndExpenses":
              return this.$transF('title')
            case "inventory":
              return '¡Gestiona tu inventario! 📦'
            default:
              return '¡Crea tu primera factura! 📝'
          }
      } else if (this.mexAccounting) {
        return this.$transF('mexTitle')
      }

      return this.$transF('title')
    },

    subtitle() {
      if (this.onlyInvoicing) {
        return "Descubre cómo agilizar la facturación por los servicios de salud que ofreces."
      } else if (this.onlyBySearch) {
        return "Haz un recorrido rápido y empieza a registrar tus ventas"
      } else if (this.onlyAccountant) {
        return this.$transF('subtitleAccountant')
      } else if (this.onlyByProfile) {
        switch (this.firstStepsByProfile) {
            case "incomeAndExpenses":
              return this.$transF('subtitle')
            case "inventory":
              return 'Aprende a crear tus productos y toma el control de lo que vendes'
            default:
              return 'Haz un recorrido rápido y empieza a registrar tus ventas'
          }
      } else if (this.mexAccounting) {
        return this.$transF('mexSubtitle')
      }

      return this.$transF('subtitle')
    },

    skipFeCookieName() {
      return `${firstStepsCookieBaseName}-skip-fe-${this.company.id}`
    },

    notShowAgainCookieName() {
      return `${firstStepsCookieBaseName}-no-show-${this.company.id}`
    },

    firstSeenCookieName() {
      return `${firstStepsCookieBaseName}-first-seen-${this.company.id}`
    },

    feSkipped() {
      return Cookies.get(this.skipFeCookieName)
    },

    urlAcademyVisited() {
      return `${firstStepsCookieBaseName}-academy-visited-${this.company.id}`
    },

    urlTaxConciliatorVisited() {
      return `${firstStepsCookieBaseName}-taxCon-visited-${this.company.id}`
    },

    urlBankConciliatorVisited() {
      return `${firstStepsCookieBaseName}-bankCon-visited-${this.company.id}`
    },

    urlAccountingReportsVisited() {
      return `${firstStepsCookieBaseName}-accRpts-visited-${this.company.id}`
    },

    urlCatalogAccountsVisited() {
      return `${firstStepsCookieBaseName}-catAcc-visited-${this.company.id}`
    },

    urlAccountingAdjustmentsVisited() {
      return `${firstStepsCookieBaseName}-accAdj-visited-${this.company.id}`
    },

    urlElectronicMailboxVisited() {
      return `${firstStepsCookieBaseName}-mb-visited-${this.company.id}`
    },

    urlAccountantSpaceVisited() {
      return `${firstStepsCookieBaseName}-accSpace-visited-${this.company.id}`
    },

    firstStepsByProfile() {
      return Cookies.get(`${firstStepsCookieBaseName}-profile-${this.company.id}`)
    },

    onboardingTypeCookieName() {
      return `al-onboarding-type`
    },

    happyWeekDateCookieName() {
      return `${firstStepsCookieBaseName}-hw-${this.company.id}`
    },

    happyWeekStartTimeCookieName() {
      return `${firstStepsCookieBaseName}-hw-time-${this.company.id}`
    },

    happyWeekBg() {
      // let userCountry = this.company.applicationVersion;

      // if (userCountry === "mexico") {
      //   return "happy-week-background-3";
      // }

      return "happy-week-background-3";
    }
  },
  methods: {
    isCompleted (achievement) {
      return achievement.actions.includes('completed')
    },

    isInProgress (achievement) {
      return achievement.actions.includes('inProgress')
    },

    getStepStatus(achievement) {
      if (this.isCompleted(achievement)) {
        return 'completed'
      } else if (this.isInProgress(achievement)) {
        return 'inProgress'
      }

      return 'current'
    },

    getAchievementTitle(keyword) {
      switch (keyword) {
        case 'completed':
          return this.$transF('completed')
        default:
          return this.$transF('begin')
      }
    },

    hasSkip (achievement) {
      return achievement.actions.includes('skip')
    },

    changeNotShowAgain() {
      this.notShowAgain = !this.notShowAgain
    },

    closeSkipModal() {
      this.showSkipModal = false;

      Cookies.set(this.skipFeCookieName, true, {
        expires: 30,
        domain: ".alegra.com",
        sameSite: "strict",
        secure: true
      })

      let stepPosition = this.achievements.findIndex(a => a.id === "badge-fe")

      if (stepPosition !== -1) {
        triggerGTMEventNewTaxonomy("ac-first-step-managed", {
          data: this.company,
          user: this.user,
          additional: {
            step: 'electronic invoice',
            position: stepPosition + 1,
            action: "skipped"
          }
        });
      }

      this.achievementsArray = this.achievements.filter((achievement) => achievement.id !== "badge-fe")
    },

    cancelCloseModal() {
      this.showCloseModal = false;
      this.notShowAgain= false;
    },

    closeModal() {
      if (this.notShowAgain) {
        Cookies.set(this.notShowAgainCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })
      }

      triggerGTMEventNewTaxonomy("ac-first-step-closed", {
        data: this.company,
        user: this.user,
        additional: {
          action: this.notShowAgain ? "Definitive" : "Temporal"
        }
      });

      this.cancelCloseModal()
      this.show= false;
    },

    // EDITAR AQUI
    redirect(achievement, isCompleted) {
      const urls = {
        "badge-academy":
          "https://www.alegra.com/academy/",

        "badge-taxConciliator": achievement.link,
        "badge-bankConciliator": achievement.link,
        "badge-accountingReports": achievement.link,
        "badge-catalogAccounts": achievement.link,
        "badge-accountingAdjustments": achievement.link,
        "badge-electronicMailbox": achievement.link,
        "badge-accountantSpace": achievement.link,
      }

      const cookiesNames = {
        "badge-academy":
          this.urlAcademyVisited,

        "badge-taxConciliator": this.urlTaxConciliatorVisited,
        "badge-bankConciliator": this.urlBankConciliatorVisited,
        "badge-accountingReports": this.urlAccountingReportsVisited,
        "badge-catalogAccounts": this.urlCatalogAccountsVisited,
        "badge-accountingAdjustments": this.urlAccountingAdjustmentsVisited,
        "badge-electronicMailbox": this.urlElectronicMailboxVisited,
        "badge-accountantSpace": this.urlAccountantSpaceVisited,
      }

      if(!isCompleted) {
        Cookies.set(cookiesNames[achievement.id], true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })
      }

      let stepPosition = this.achievementsArray.findIndex(a => a.id === achievement.id)

      if (stepPosition !== -1) {
        triggerGTMEventNewTaxonomy("ac-first-step-managed", {
          data: this.company,
          user: this.user,
          additional: {
            step: achievement.stepName,
            position: stepPosition + 1,
            action: "started"
          }
        });
      }

      window.open(urls[achievement.id], urls[achievement.id].startsWith('https://') ? "_blank" : "_self")
    },

    // EDITAR AQUI
    idsToRedirectAndComplete(id) {
      let idsAccountant = [
        'badge-accountingReports',
        'badge-bankConciliator',
        'badge-catalogAccounts',
        'badge-accountingAdjustments',
        'badge-electronicMailbox',
        'badge-accountantSpace',
        'badge-taxConciliator'
      ];

      if (id === "badge-academy") {
        return true;
      } else if (this.onlyAccountant && idsAccountant.includes(id)) {
        return true;
      }

      return false;
    },

    redirectSteps(achievement) {
      if(this.isCompleted(achievement)) return;

      let stepCookieName = `${firstStepsCookieBaseName}-${achievement.id}-${this.company.id}`

      if(!Cookies.get(stepCookieName)) {
        Cookies.set(stepCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })

      }

      let stepPosition = this.achievementsArray.findIndex(a => a.id === achievement.id)

      if (stepPosition !== -1) {
        triggerGTMEventNewTaxonomy("ac-first-step-managed", {
          data: this.company,
          user: this.user,
          additional: {
            step: achievement.stepName,
            position: stepPosition + 1,
            action: "started"
          }
        });
      }

      window.open(achievement.link, achievement.link.startsWith('https://') ? "_blank" : "_self")
    },

    getNumberOfStepsCompleted() {
      let stepsCompleted = 0;
      this.achievements.forEach((achievement) => {
        if(achievement.actions.includes('completed')) stepsCompleted++
      })
      return stepsCompleted
    },

    triggerStepsEvents(achievementsArray) {
      achievementsArray.forEach((achievement) => {
        if(achievement.id === "badge-academy") return;

        let stepCookieName = `${firstStepsCookieBaseName}-${achievement.id}-${this.company.id}`;

        if(Cookies.get(stepCookieName)) {
          Cookies.remove(stepCookieName, { domain: ".alegra.com", path: "/" });
        }

      })
    },

    completeFirstSteps() {
      if(!Cookies.get(this.notShowAgainCookieName) && checkAllStepsCompleted(this.achievements)) {
        Cookies.set(this.notShowAgainCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })

        this.isAllCompleted = true;
        //this.show = false;
      }
    },

    checkElapsedTimeLauncherNotification() {
      const registryDateStr = this.company.registryDate;
      const timezone = this.company.timezone;

      if(registryDateStr && timezone) {
        const registryDateLocal = DateTime.fromFormat(registryDateStr, "yyyy-MM-dd HH:mm:ss");
        Settings.defaultZoneName = timezone
        const localDate = DateTime.local().setZone(timezone);

        const elapsedTime = localDate - registryDateLocal;
        const twoMinutes = 2 * 60 * 1000;

        if (elapsedTime >= twoMinutes) {
          this.showLauncherNotification();
        } else {
          const timeLeft = twoMinutes - elapsedTime;

          setTimeout(() => {
            this.showLauncherNotification();
          }, timeLeft);
        }
      }
    },

    showLauncherNotification() {
      const smileLayout = window._smileLayout;
      if(smileLayout) {
        smileLayout.showLauncherTooltip.value = true;
        Cookies.remove(this.onboardingTypeCookieName, {
          domain: ".alegra.com",
          path: "/"
        });
      }
    },

    goToProductsPlansHappyWeek() {
      let currentDate = moment().format('DD/MM/YYYY')

      Cookies.set(this.happyWeekDateCookieName, currentDate, {
        expires: 30,
        domain: ".alegra.com",
        sameSite: "strict",
        secure: true
      })

      if(Cookies.get(this.happyWeekStartTimeCookieName)) {
        Cookies.remove(this.happyWeekStartTimeCookieName, { domain: ".alegra.com", path: "/" });
      }

      triggerGTMEventNewTaxonomy("exp-ac-banner-hw-managed", {
        data: this.company,
        user: this.user,
        additional: {
          action: "started-payment"
        }
      });

      window.open(`${ENVIROMENT[appEnviroment]["MI_ALEGRA"]}/subscriptions/products/alegra-web/plans`, "_blank")
      this.showHappyWeekModal = false;
    },

    closeHappyWeekModal() {
      this.showHappyWeekModal = false;
      let currentDate = moment().format('DD/MM/YYYY')

      Cookies.set(this.happyWeekDateCookieName, currentDate, {
        expires: 30,
        domain: ".alegra.com",
        sameSite: "strict",
        secure: true
      })

      if(Cookies.get(this.happyWeekStartTimeCookieName)) {
        Cookies.remove(this.happyWeekStartTimeCookieName, { domain: ".alegra.com", path: "/" });
      }

      triggerGTMEventNewTaxonomy("exp-ac-banner-hw-managed", {
        data: this.company,
        user: this.user,
        additional: {
          action: "skipped"
        }
      });
    },

    checkElapsedTimeHappyWeekModal() {
      const registryDateStr = Cookies.get(this.happyWeekStartTimeCookieName);
      // const registryDateStr = this.company.registryDate;
      // const timezone = this.company.timezone;

      // if(registryDateStr && timezone) {
      if(registryDateStr) {
        const registryDateLocal = DateTime.fromFormat(registryDateStr, "yyyy-MM-dd HH:mm:ss");
        // Settings.defaultZoneName = timezone
        // const localDate = DateTime.local().setZone(timezone);
        const localDate = DateTime.local();

        const elapsedTime = localDate - registryDateLocal;
        const threeMinutes = 3 * 60 * 1000;

        if (elapsedTime >= threeMinutes) {
          this.launchHappyWeekModal();
        } else {
          const timeLeft = threeMinutes - elapsedTime;

          setTimeout(() => {
            this.launchHappyWeekModal();
          }, timeLeft);
        }
      }
    },

    launchHappyWeekModal() {
      this.showHappyWeekModal = true;
    },

    getMexAccountingStepStatus(achievement, idx) {
      if (this.isCompleted(achievement)) {
        return this.$transF('completed')
      } else if (this.isInProgress(achievement)) {
        return this.$transF('inProgress')
      } else if (idx === 0 && !this.isCompleted(achievement)) {
        return this.$transF('load')
      }

      return this.$transF('learn')
    },
  },
  beforeMount() {
    if(this.feSkipped) {
      this.achievementsArray = this.achievements.filter((achievement) => achievement.id !== "badge-fe")
    }
  },
  async mounted() {
    if(!Cookies.get(this.firstSeenCookieName)) {

      Cookies.set(this.firstSeenCookieName, true, {
          expires: 30,
          domain: ".alegra.com",
          sameSite: "strict",
          secure: true
        })
    }

    this.triggerStepsEvents(this.achievementsArray);
    this.completeFirstSteps();

    const onboardingTypeCookie = Cookies.get(this.onboardingTypeCookieName)

    if(onboardingTypeCookie &&
       onboardingTypeCookie === "home" &&
      (this.appVersion !== "colombia" || (this.appVersion === "colombia" && this.company.profile !== "accountant"))) {
      this.checkElapsedTimeLauncherNotification();
    }

    // if(!Cookies.get(this.happyWeekStartTimeCookieName) && !Cookies.get(this.happyWeekDateCookieName)) {
    //   Cookies.set(this.happyWeekStartTimeCookieName, DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'), {
    //     expires: 30,
    //     domain: ".alegra.com",
    //     sameSite: "strict",
    //     secure: true
    //   })
    // }

    // let happyWeekDateCookie = Cookies.get(this.happyWeekDateCookieName);
    // let happyWeekCountries = ['colombia', 'republicaDominicana', 'costaRica', 'panama', 'argentina', 'peru', 'spain'];
    // let userCountry = this.company.applicationVersion;
    // let isEligibleHW = false;

    // try {
    //   const response = await axios.get(`${membershipsApiUrl}/memberships/v1/subscribers/${this.company.id}/happy-week/is-eligible?product=alegra-web`, {
    //     headers: {
    //       "Authorization": `Bearer ${membershipsToken}`
    //     }
    //   })
    //   isEligibleHW = response.data.results;
    // } catch (error) {
    //   //
    // }

    // if (isEligibleHW && happyWeekCountries.includes(userCountry)) {
    //   if(happyWeekDateCookie) {
    //     const lastHappyWeekDate = moment(happyWeekDateCookie, 'DD/MM/YYYY');
    //     const currentDate = moment();
    //     if (currentDate.diff(lastHappyWeekDate, 'days') >= 2) {
    //       // this.launchHappyWeekModal()
    //       if(!Cookies.get(this.happyWeekStartTimeCookieName)) {
    //         Cookies.set(this.happyWeekStartTimeCookieName, DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'), {
    //           expires: 30,
    //           domain: ".alegra.com",
    //           sameSite: "strict",
    //           secure: true
    //         })
    //       }
    //       this.checkElapsedTimeHappyWeekModal();
    //     }
    //   } else {
    //     this.checkElapsedTimeHappyWeekModal();
    //   }
    // }
  },
  watch: {
    // achievementsArray: {
    //   deep: true,
    //   handler (value) {
    //     this.triggerStepsEvents(value);
    //     this.completeFirstSteps();
    //   }
    // },

    isAllCompleted(newValue) {
      if(newValue) {
        this.showSlide = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  background-color: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  margin: 0 auto;
  padding-bottom: 26px;
  padding-top: 26px;
  position: relative;
  width: 1024px;
  height: 235px;
  border-radius: 5px;
  border: 3px solid #00D5BD;

  /*&:after {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background-image: linear-gradient(103.05deg, #00C8D5 -2.18%, #00D5BD 62.61%, #ACFEB5 139.96%, #CEEF98 149.56%);
  }*/

  & .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    &:hover {
      color: #00b19d;
    }
  }
}

.container-texts {
  font-family: Inter;
  font-style: normal;
  text-align: center;

  & .title {
    font-weight: 600;
    font-size: 20px;
    color: #0F172A;
    line-height: 1;
  }

  & .subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #334155;
    margin-top: 7px;
    line-height: 1;
  }

}

.container-main {
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 5px;
}

.container-carousel {
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0;
  scroll-behavior: smooth;
  width: calc(100% - 8%);

  & .carousel {
    display: flex;
    flex-wrap: nowrap;

    & .hidden {
      display: none;
    }
  }
}

.achievement {
  position: relative;
  text-align: center;
  z-index: 0;
  width: 200px;
  color: #334155;

  /*&.completed::before {
    background: linear-gradient(103.05deg, #00C8D5 -2.18%, #00D5BD 62.61%, #ACFEB5 139.96%, #CEEF98 149.56%), #8BDFD6;
  }*/

  &:first-child::before {
    display: none;
  }

  & .achievement-icon {
    height: 40px;
    width: 40px;

    &.completed {
      color: #51ba78;
    }

    &.next {
      color: #94A3B8;
    }
  }

  & .texts {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: center;
  }

  & .title {
    font-family: Inter;
    color: #334155;
    font-size: 14px;
    text-align: center;
    margin: 3px 0;
  }
}

.container-actions {
  & .actions {
    font-family: Inter;
    font-weight: 600;

      & a {
        color: #00B19D;
        font-size: 14px;
        text-align: center;

        &:-webkit-any-link {
          cursor: pointer;
        }
      }

      & p {
        font-size: 14px;
        text-align: center;
        font-weight: 400;

        &.completed {
          color: #51ba78;
        }

        &.next {
          color: #94A3B8;
        }
      }

  }

}

.mx-auto {
  margin: 0 auto;
}

// ON COMPLETED ANIMATIONS
.completed-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.completed-slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //border-radius: 3px;
  background: linear-gradient(90deg, #00C8D5 -25.99%, #00D5BD 21.02%, #ACFEB5 80.63%);
}

.completed-slide-leave-active,
.completed-slide-enter-active {
  transition: 0.5s;
}
.completed-slide-enter {
  transform: translate(-100%, 0);
}
.completed-slide-leave-to {
  transform: translate(100%, 0);
}

.check-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55px;
  margin: 0 auto;
  height: 131px;
  width: 137px;
}

.check-fade-enter-active {
  animation: bounce-in 1s;
}

.fade-out-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-out-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    bottom: -131px;
  }
  20% {
    bottom: 131px;
  }
  40% {
    bottom: 30px;
  }
  60% {
    bottom: 70px;
  }
  85% {
    bottom: 45px;
  }
  100% {
    bottom: 55px;
  }
}

// MODAL
.modal {
  background: rgba(255, 255, 255, 0.5) !important;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .close-container {
    display: flex;
    justify-content: flex-end;
    padding: 13px 13px 0 0;
    width: 100%;

    & .close {
      cursor: pointer;
    }
  }


  & .icon-badge {
  background: linear-gradient(180deg,rgba(47,208,190,0.2) 0,rgba(47,208,190,0) 167.83%);
  width: 67px;
  height: 67px;
  border-radius: 100%;
  margin-top: 20px;

    & img {
      width: auto;
      margin: 19px 21px;
    }
  }

  & .modal-texts {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    color: #707070;
    margin-top: 25px;

    & .title {
      font-size: 24px !important;
      line-height: 32px;
      font-weight: 400;
    }

    & .text {
      font-size: 14px !important;
      line-height: 21px;
      margin: 7px 65px 0 63px;
    }

    & .text-close {
      font-size: 14px !important;
      line-height: 21px;
      margin: 7px 50px 0 50px;
    }
  }

  & .modal-button {
    margin-top: 23px;

    &__small {
      font-size: 12px !important;
      font-weight: 500;
    }
  }

  & .alert-wrapper {
    width: 423px;
    padding: 3px 20px 20px 20px;
    margin-top: 40px;

    & .alert-info {
      display: flex;
      flex-direction: row;
      background: #fafafa;
      border-radius: 8px;
      border: 0;
      padding: 10px 15px;

      & div.alert-container-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & div .icon {
        color: #2e6d82;
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }

      & .alert-texts {
        font-family: Roboto;
        font-style: normal;

        & .alert-title {
          font-weight: 400;
          font-size: 12px;
          color: #6f6f6f;
          float: none;
        }

        & .alert-content {
          font-weight: normal;
          font-size: 11px;
          color: #949494;
          float: none;
          line-height: 14px;

          & a {
            color: #2e6d82;
            border: 1px solid #b3e8f3;
            padding: 1px 10px;
            border-radius: 30px;
            text-decoration: none;
            font-weight: 500;
          }
        }
      }
    }
  }
}

::v-deep .card.default-width {
  width: 100%;

  @media (min-width: 390px) {
    width: 390px !important;
  }

  @media (min-width: 480px) {
    width: 480px !important;
  }

  @media (min-width: 650px) {
    width: 650px !important;
  }
}


// MODAL HAPPY WEEK
.modal-hw-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #18283F !important;
  width: 100%;
  height: 360px;
  overflow: hidden;

  @media (min-width: 390px) {
    width: 388px !important;
    height: 410px;
  }

  @media (min-width: 480px) {
    width: 478px !important;
    height: 430px;
  }

  @media (min-width: 650px) {
    width: 648px !important;
    height: 475px;
  }


  & .close-hw-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 15px 0 auto;
    background-color: #F5F5F7;
    width: 30px;
    height: 30px;
    border-radius: 999px;

    & .close {
      cursor: pointer;
    }
  }

  & .hw-background-container {
    width: 85%;

    @media (min-width: 390px) {
      width: 325px !important;
    }

    @media (min-width: 480px) {
      width: 415px !important;
    }

    @media (min-width: 650px) {
      width: 585px !important;
    }
  }

  & .hw-cta-container {
    width: 150px;
    height: 40px;
    position: absolute;
    margin-top: 260px;

    @media (min-width: 390px) {
      margin-top: 290px;
    }

    @media (min-width: 480px) {
      margin-top: 320px;
    }

    @media (min-width: 650px) {
      margin-top: 360px;
      width: 175px;
    }

    &__button {
      width: 100%;
      height: 100%;
      background-color: #00D6BC;
      color: #18283F;
      font-family: Inter;
      font-weight: 800;
      font-size: 20px;
      border-radius: 15px;
      border: none;

      @media (min-width: 650px) {
        font-size: 24px;
      }
    }
  }

  & .hw-footer {
    position: absolute;
    margin-top: 320px;

    @media (min-width: 390px) {
      margin-top: 360px;
    }

    @media (min-width: 480px) {
      margin-top: 390px;
    }

    @media (min-width: 650px) {
      margin-top: 435px;
    }

    & a {
      color: white;
      font-family: Inter;
      font-weight: 400;
      font-size: 10px;
    }
  }
}
</style>
