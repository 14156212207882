<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5303 14.4697C28.8232 14.7626 28.8232 15.2374 28.5303 15.5303L18.5303 25.5303C18.2374 25.8232 17.7626 25.8232 17.4697 25.5303L12.4697 20.5303C12.1768 20.2374 12.1768 19.7626 12.4697 19.4697C12.7626 19.1768 13.2374 19.1768 13.5303 19.4697L18 23.9393L27.4697 14.4697C27.7626 14.1768 28.2374 14.1768 28.5303 14.4697Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStepCompleted"
}
</script>
