<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      d="M19.7046 10.3106C19.8932 10.2298 20.1068 10.2298 20.2954 10.3106L27.2954 13.3106C27.6762 13.4738 27.8525 13.9147 27.6894 14.2954C27.5262 14.6762 27.0853 14.8525 26.7046 14.6894L20 11.816L13.2954 14.6894C12.9147 14.8525 12.4738 14.6762 12.3106 14.2954C12.1475 13.9147 12.3238 13.4738 12.7046 13.3106L19.7046 10.3106Z"
      fill="currentColor"
    />
    <path
      d="M16.75 22C16.75 21.5858 16.4142 21.25 16 21.25C15.5858 21.25 15.25 21.5858 15.25 22V25C15.25 25.4142 15.5858 25.75 16 25.75C16.4142 25.75 16.75 25.4142 16.75 25V22Z"
      fill="currentColor"
    />
    <path
      d="M20 21.25C20.4142 21.25 20.75 21.5858 20.75 22V25C20.75 25.4142 20.4142 25.75 20 25.75C19.5858 25.75 19.25 25.4142 19.25 25V22C19.25 21.5858 19.5858 21.25 20 21.25Z"
      fill="currentColor"
    />
    <path
      d="M24.75 22C24.75 21.5858 24.4142 21.25 24 21.25C23.5858 21.25 23.25 21.5858 23.25 22V25C23.25 25.4142 23.5858 25.75 24 25.75C24.4142 25.75 24.75 25.4142 24.75 25V22Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 17.25C10.5858 17.25 10.25 17.5858 10.25 18C10.25 18.4142 10.5858 18.75 11 18.75H11.25V28.25H11C10.5858 28.25 10.25 28.5858 10.25 29C10.25 29.4142 10.5858 29.75 11 29.75H29C29.4142 29.75 29.75 29.4142 29.75 29C29.75 28.5858 29.4142 28.25 29 28.25H28.75V18.75H29C29.4142 18.75 29.75 18.4142 29.75 18C29.75 17.5858 29.4142 17.25 29 17.25H11ZM27.25 18.75V28.25H12.75V18.75H27.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBankConciliation"
}
</script>
