export default {
  electronicInvoicing: {
    title: "Facturación <br/>electrónica",
    subtitle: "",
  },
  enableTBai: {
    title: "Habilita <br/>TicketBAI",
    subtitle: ""
  },
  firstInvoice: {
    title: "Crea tu primera <br/>factura",
    subtitle: "Genera tu primera venta y administra tus ingresos"
  },
  organizeInventory: {
    title: "Organiza tu <br/>inventario",
    subtitle: "Crea tus productos y servicios con todas sus características"
  },
  manageContacts: {
    title: "Gestiona tus <br/>contactos",
    subtitle: "Agrega tus clientes y proveedores a tu base de datos"
  },
  manageUsers: {
    title: "Administra tus <br/>usuarios",
    subtitle: "Crea perfiles de usuario y define su nivel de acceso al sistema"
  },
  configureNumbering: {
    title: "Configura tus <br/>numeraciones",
    subtitle: "Crea múltiples numeraciones y prefijos para todos tus documentos"
  },
  academy: {
    title: "Alegra <br/>Academy",
    subtitle: "Conoce Alegra Academy"
  },
  accountCreated: {
    title: "Crea tu <br/>cuenta",
    subtitle: ""
  },
  registerACustomer: {
    title: "Registra un<br/> cliente",
    subtitle: ""
  },
  createAProduct: {
    title: "Crea un<br/> producto",
    subtitle: ""
  },
  firstInvoiceBySearch: {
    title: "Haz tu primera<br/> factura",
    subtitle: ""
  },
  firstSale: {
    title: "Haz tu primera<br/> venta",
    subtitle: ""
  },
  electronicInvoicingMex: {
    title: "Activar facturación",
    subtitle: "Activa la facturación electrónica en menos de 10 minutos.",
  },
  firstInvoiceMex: {
    title: "Registra tu primera venta",
    subtitle: "Genera tu primera venta y administra tus ingresos"
  },
  title: "Te damos la bienvenida a Alegra",
  subtitle: "Para comenzar te recomendamos estos pasos",
  begin: "Comenzar",
  inProgress: "En proceso",
  completed: "Completado",
  skip: "Omitir",
  cancel: "Cancelar",
  confirm: "Confirmar",
  youAreAboutToCloseThisSection: "Estás por cerrar esta sección",
  youAreAboutToCloseThisSectionDescription: "Si ya completaste tus primeros pasos o deseas ocultarlos temporalmente, puedes confirmar y seguir a tu ritmo dándole superpoderes a tu negocio",
  noShowAgain: "No volver a mostrar los primeros pasos",
  byProfile: {
    invoicing: {
      client: {
        title: "Crea un cliente",
      },
      product: {
        title: "Crea un producto",
      },
      invoice: {
        title: "Crea una factura de venta",
      },
      payment: {
        title: "Añade un pago a tus facturas",
      }
    },
    inventory: {
      product: {
        title: "Crea un producto",
      },
      warehouse: {
        title: "Crea tus almacenes",
      },
      adjustments: {
        title: "Ajustes de inventario",
      },
      priceList: {
        title: "Crea tus listas de precios",
      }
    },
    incomesAndExpenses: {
      client: {
        title: "Crear un contacto",
      },
      product: {
        title: "Crea un producto",
      },
      invoice: {
        title: "Crea tu factura de compra",
      },
      payment: {
        title: "Añade un pago a tu factura",
      }
    }
  },
  chartOfAccounts: {
    title: "Catálogo de cuentas",
    subtitle: ""
  },
  openingBalances: {
    title: "Saldos iniciales",
    subtitle: ""
  },
  vouchersAndAdjustments: {
    title: "Comprobantes y ajustes",
    subtitle: ""
  },
  accountingReports: {
    title: "Reportes contables",
    subtitle: ""
  },
  alegraAccountant: {
    title: "Alegra Contador",
    subtitle: ""
  },
  alegraAccountantSpace: {
    title: "Espacio contador",
    subtitle: ""
  },
  titleAccountant: "¡Funciones clave para optimizar tu trabajo! ✨",
  subtitleAccountant: "Conoce las funciones diseñadas para automatizar tus tareas",
  // MEX
  mexTitle: "Aprende a usar Alegra Contabilidad 💡",
  mexSubtitle: "Conoce las principales funciones que le van a dar superpoderes a tu negocio",
  invoiceStamps: "Sellos para facturar",
  salesInvoices: "Facturas de venta",
  productsAndServices: "Productos y servicios",
  contacts: "Contactos",
  usersAndPermissions: "Usuarios y permisos",
  load: "Cargar",
  learn: "Conocer",
  accountant: {
    taxConciliator: "Conciliación fiscal<br/> inteligente",
    bankConciliator: "Conciliación de <br/>cuentas bancarias",
    catalogAccounts: "Catálogo de cuentas y<br/> parámetros contables",
    accountingAdjustments: "Importación de<br/> registros contables",
    electronicMailbox: "Buzón de recepción<br/> electrónico",
    accountingReports: "Reportes contables<br/> inteligentes",
    accountantSpace: "Gestión de múltiples<br/> empresas",
  }
}
