<template>
  <div v-if="show" class="resico">
    <sm-icon class="resico__close" icon="close" size="medium" @click="show = !show" />
    <div class="resico__container">
      <p class="resico__title">¡Recibe los beneficios del RESICO!</p>
      <p class="resico__subtitle">
        Descubre cómo implementar este nuevo régimen y empieza a facturar fácil con Alegra.
      </p>
      <div class="resico__steps">
        <div class="resico__step">
          <div class="resico__icon">
            <img src="@/assets/new-user-in-progress.svg" alt="alegra" />
          </div>
          <p class="resico__step__title resico__step__title__completed">Crea tu cuenta</p>
          <small class="resico__step__subtitle resico__step__completed">Completado</small>
          <div class="resico__step__link__progress" :class="{ completed: step > 0 }"></div>
        </div>
        <div class="resico__step">
          <div
            class="resico__icon"
            :class="{
              resico__icon__in__progress: step == 1,
              resico__icon__no__completed: step < 1
            }"
          >
            <img
              v-if="step == 1"
              src="@/assets/Icon-beneficios-resico-in-progress.svg"
              alt="alegra"
            />
            <img
              v-else-if="step >= 2 || knowResicoView"
              src="@/assets/Icon-beneficios-resico-completed.svg"
              alt="alegra"
            />
            <img v-else src="@/assets/Icon-beneficios-resico-no-completed.svg" alt="alegra" />
          </div>
          <p class="resico__step__title">Conoce los beneficios RESICO</p>
          <small class="resico__step__subtitle">
            <a href="#!" @click="redirect('url-conoce-resico')">Empezar</a>
          </small>
          <div
            class="resico__step__link__progress resico__step__link__progress__step2"
            :class="{ completed: step > 1 }"
          ></div>
        </div>
        <div class="resico__step">
          <div
            class="resico__icon"
            :class="{
              resico__icon__in__progress: step == 2,
              resico__icon__no__completed: step < 2 && !activateResicoView
            }"
          >
            <img v-if="step == 2" src="@/assets/Icon-resico-in-progress.svg" alt="alegra" />
            <img
              v-else-if="step >= 3 || activateResicoView"
              src="@/assets/Icon-resico-completed.svg"
              alt="alegra"
            />
            <img v-else src="@/assets/Icon-resico-no-completed.svg" alt="alegra" />
          </div>
          <p class="resico__step__title">Actívate en el RESICO</p>
          <small class="resico__step__subtitle">
            <a href="#!" @click="redirect('url-activate-resico')">Empezar</a>
          </small>
          <div
            class="resico__step__link__progress resico__step__link__progress__step3"
            :class="{ completed: step > 2 }"
          ></div>
        </div>
        <div class="resico__step">
          <div
            class="resico__icon"
            :class="{
              resico__icon__in__progress: step == 3,
              resico__icon__no__completed: step < 3 && !invoice
            }"
          >
            <img
              v-if="step == 3"
              src="@/assets/Icon-crear-primera-factura-in-progress.svg"
              alt="alegra"
            />
            <img
              v-else-if="step >= 4 || invoice"
              src="@/assets/Icon-crear-primera-factura-completed.svg"
              alt="alegra"
            />
            <img v-else src="@/assets/Icon-crear-primera-factura-no-completed.svg" alt="alegra" />
          </div>
          <p class="resico__step__title">Crea tu primera factura</p>
          <small class="resico__step__subtitle">
            <a href="/invoice/add">Empezar</a>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie"

export default {
  name: "NotHaveRegime",
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    invoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    step() {
      let step = 1

      if (Cookies.get("url-conoce-resico")) {
        step = 2
      } else {
        return step
      }

      if (Cookies.get("url-activate-resico")) {
        step = 3
      } else {
        return step
      }

      if (this.invoice) {
        step = 4
      }

      return step
    },
    activateResicoView() {
      return Cookies.get("url-activate-resico")

    },
    knowResicoView() {
      return Cookies.get("url-conoce-resico")
    }
  },
  methods: {
    redirect(url) {
      const urls = {
        "url-activate-resico":
          "https://www.youtube.com/watch?v=NkeuTDWUwAY",
        "url-conoce-resico": "https://youtu.be/e0_OoMZA_uM"
      }

      Cookies.set(url, true, {
        expires: 30,
        domain: ".alegra.com",
        sameSite: "strict",
        secure: true
      })

      window.open(urls[url], "_blank")
    }
  }
}
</script>

<style lang="scss" scoped>
.resico {
  background-color: #fff;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 10%);
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  margin: 0 auto;
  padding-bottom: 12px;
  padding-top: 12px;
  position: relative;
  width: 1024px;
  position: relative;

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  &__container {
    text-align: center;
    padding: 30px 0;
  }

  &__title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #47536b;

    span {
      font-weight: 500;
    }
  }

  &__subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    padding: 28px 51px 0px 51px;
  }

  &__step {
    position: relative;

    &__title {
      font-family: Roboto;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #707070;
    }

    &__title__completed {
      color: #00b19d;
    }

    &__subtitle {
      font-family: Roboto;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #a3adc2;
      text-decoration-line: underline;

      a {
        color: #a3adc2;
      }

      a:hover {
        color: #00b19d;
      }
    }

    &__completed {
      text-decoration-line: none;
    }

    &__link__progress {
      position: absolute;
      top: 27px;
      left: 63px;
      width: 251%;
      height: 6px;
      background-color: #f7f7f7;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;

      &__step2 {
        left: 112px;
        width: 130%;
      }

      &__step3 {
        left: 85px;
        width: 165%;
      }
    }
  }

  &__icon {
    position: relative;
    left: calc(50% - 20px);
    top: 10px;
    z-index: 1;
    background-color: #00b19d;
    width: 38px;
    height: 38px;
    border-radius: 30px;
    border: 1px solid white;
    padding: 5px;
    margin-bottom: 10px;

    img {
      width: 100% !important;
      height: auto !important;
      z-index: 1;
    }
  }

  &__icon__in__progress {
    background-color: white;
    border: 1px solid #00b19d;
  }

  &__icon__no__completed {
    background-color: white;
    border: 1px solid #a3adc2;
  }

  .completed {
    background-color: #00b19d;
  }
}
</style>
