export default {
  electronicInvoicing: {
    title: "Electronic <br/>invoicing",
    subtitle: "",
  },
  firstInvoice: {
    title: "Create your first <br/>invoice",
    subtitle: "Generate your first invoice and manage your incomes"
  },
  organizeInventory: {
    title: "Organize your <br/>inventory",
    subtitle: "Add product or service with all their characteristics"
  },
  manageContacts: {
    title: "Manage your <br/>contacts",
    subtitle: "Add your customers and suppliers to your database"
  },
  manageUsers: {
    title: "Manage your <br/>users",
    subtitle: "Create user profiles and define their level of access to the system"
  },
  configureNumbering: {
    title: "Configure your <br/>numbering",
    subtitle: "Add multiple numbering and prefixes for all your documents"
  },
  academy: {
    title: "Alegra <br/>Academy",
    subtitle: "Get to know Alegra Academy"
  },
  accountCreated: {
    title: "Create your <br/>account",
    subtitle: ""
  },
  registerACustomer: {
    title: "Register a <br/>customer",
    subtitle: ""
  },
  createAProduct: {
    title: "Create a <br/>product",
    subtitle: ""
  },
  firstInvoiceBySearch: {
    title: "Make your first <br/>invoice",
    subtitle: ""
  },
  firstSale: {
    title: "Make your first <br/>sale",
    subtitle: ""
  },
  electronicInvoicingMex: {
    title: "Activate invoicing",
    subtitle: "",
  },
  firstInvoiceMex: {
    title: "Register your first sale",
    subtitle: "Generate your first invoice and manage your incomes"
  },
  title: "We welcome you to Alegra",
  subtitle: "To start we recommend these steps",
  begin: "Begin",
  inProgress: "In progress",
  completed: "Completed",
  skip: "Skip",
  cancel: "Cancel",
  confirm: "Confirm",
  youAreAboutToCloseThisSection: "You are about to close this section",
  youAreAboutToCloseThisSectionDescription: "If you have already completed your first steps or want to hide them temporarily, you can confirm and continue at your own pace giving your business superpowers",
  noShowAgain: "Not showing the first steps again",
  byProfile: {
    invoicing: {
      client: {
        title: "",
      },
      product: {
        title: "",
      },
      invoice: {
        title: "",
      },
      payment: {
        title: "",
      }
    },
    inventory: {
      product: {
        title: "",
      },
      warehouse: {
        title: "",
      },
      adjustments: {
        title: "",
      },
      priceList: {
        title: "",
      }
    },
    incomesAndExpenses: {
      client: {
        title: "",
      },
      product: {
        title: "",
      },
      invoice: {
        title: "",
      },
      payment: {
        title: "",
      }
    }
  },
  chartOfAccounts: {
    title: "Chart of accounts",
    subtitle: ""
  },
  openingBalances: {
    title: "Opening balances",
    subtitle: ""
  },
  vouchersAndAdjustments: {
    title: "Vouchers and Adjustments",
    subtitle: ""
  },
  accountingReports: {
    title: "Accounting Reports",
    subtitle: ""
  },
  alegraAccountant: {
    title: "Alegra Accountant",
    subtitle: ""
  },
  alegraAccountantSpace: {
    title: "",
    subtitle: ""
  },
  titleAccountant: "Key features to optimize your work! ✨",
  subtitleAccountant: "Learn about features designed to automate your tasks",
  learn: "Learn",
  accountant: {
    taxConciliator: "Smart tax reconciliation<br/> with DIAN",
    bankConciliator: "Bank accounts<br/> reconciliation",
    catalogAccounts: "Chart of accounts and<br/> accounting parameters",
    accountingAdjustments: "Import of accounting<br/> documents",
    electronicMailbox: "Mailbox to receipt of<br/> electronic documents",
    accountingReports: "Smart accounting<br/> reports",
    accountantSpace: "Multi-company<br/> management",
  }
}
