<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
  <path d="M17 14.25C16.5858 14.25 16.25 14.5858 16.25 15C16.25 15.4142 16.5858 15.75 17 15.75H18C18.4142 15.75 18.75 15.4142 18.75 15C18.75 14.5858 18.4142 14.25 18 14.25H17Z" fill="currentColor"/>
  <path d="M16.25 21C16.25 20.5858 16.5858 20.25 17 20.25H23C23.4142 20.25 23.75 20.5858 23.75 21C23.75 21.4142 23.4142 21.75 23 21.75H17C16.5858 21.75 16.25 21.4142 16.25 21Z" fill="currentColor"/>
  <path d="M21 24.25C20.5858 24.25 20.25 24.5858 20.25 25C20.25 25.4142 20.5858 25.75 21 25.75H23C23.4142 25.75 23.75 25.4142 23.75 25C23.75 24.5858 23.4142 24.25 23 24.25H21Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0555 11.0555C13.5712 10.5397 14.2707 10.25 15 10.25H22C22.1989 10.25 22.3897 10.329 22.5303 10.4697L27.5303 15.4697C27.671 15.6103 27.75 15.8011 27.75 16V27C27.75 27.7293 27.4603 28.4288 26.9445 28.9445C26.4288 29.4603 25.7293 29.75 25 29.75H15C14.2707 29.75 13.5712 29.4603 13.0555 28.9445C12.5397 28.4288 12.25 27.7293 12.25 27V13C12.25 12.2707 12.5397 11.5712 13.0555 11.0555ZM15 11.75C14.6685 11.75 14.3505 11.8817 14.1161 12.1161C13.8817 12.3505 13.75 12.6685 13.75 13V27C13.75 27.3315 13.8817 27.6495 14.1161 27.8839C14.3505 28.1183 14.6685 28.25 15 28.25H25C25.3315 28.25 25.6495 28.1183 25.8839 27.8839C26.1183 27.6495 26.25 27.3315 26.25 27V16.7502H23C22.5359 16.7502 22.0908 16.5659 21.7626 16.2377C21.4344 15.9095 21.25 15.4644 21.25 15.0002V11.75H15ZM22.75 12.8107L25.1896 15.2502H23C22.9337 15.2502 22.8701 15.2239 22.8232 15.177C22.7763 15.1301 22.75 15.0665 22.75 15.0002V12.8107Z" fill="currentColor"/>
  <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "IconBadgeFe"
}
</script>
