<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<path d="M19.6954 11.3146C19.8893 11.2285 20.1107 11.2285 20.3046 11.3146L29.3046 15.3146C29.5754 15.435 29.75 15.7036 29.75 16V29C29.75 29.4142 29.4142 29.75 29 29.75C28.5858 29.75 28.25 29.4142 28.25 29V16.4874L20 12.8207L11.75 16.4874V29C11.75 29.4142 11.4142 29.75 11 29.75C10.5858 29.75 10.25 29.4142 10.25 29V16C10.25 15.7036 10.4246 15.435 10.6954 15.3146L19.6954 11.3146Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 20.25V20C21.75 19.5359 21.5656 19.0908 21.2374 18.7626C20.9092 18.4344 20.4641 18.25 20 18.25H18C17.5359 18.25 17.0908 18.4344 16.7626 18.7626C16.4344 19.0908 16.25 19.5359 16.25 20V22.25H15C14.5858 22.25 14.25 22.5858 14.25 23V29C14.25 29.4142 14.5858 29.75 15 29.75H25C25.4142 29.75 25.75 29.4142 25.75 29V21C25.75 20.5858 25.4142 20.25 25 20.25H21.75ZM20.25 20C20.25 19.9337 20.2237 19.8701 20.1768 19.8232C20.1299 19.7763 20.0663 19.75 20 19.75H18C17.9337 19.75 17.8701 19.7763 17.8232 19.8232C17.7763 19.8701 17.75 19.9337 17.75 20V22.25H20.25V20ZM21.75 21.75H24.25V28.25H21.75V21.75ZM20.25 23.75H15.75V28.25H20.25V23.75Z" fill="currentColor"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor"/>
</svg>
</template>

<script>
export default {
  name: "IconBadgeProduct"
}
</script>
