<template>
  <layout-default :loading="loading" :error="error" :reload-component="loadUserInfo">
    <app-first-steps v-if="!loading">
      <slot></slot>
    </app-first-steps>
  </layout-default>
</template>

<script>
import layoutDefault from "@/layouts/Default.vue"
import webComponentConfig from "@/mixins/webComponentConfig.js"
import AppFirstSteps from "@/components/AppFirstSteps.vue"

export default {
  components: {
    layoutDefault,
    AppFirstSteps
  },
  mixins: [webComponentConfig]
}
</script>

<style></style>
