import Vue from "vue"
import Vuex from "vuex"
import { hasModulePlugin, registerModuleOncePlugin } from "./utils"

// import example from "./example/index"
import config from "./config/index"

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [hasModulePlugin, registerModuleOncePlugin],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    // example,
    config
  }
})

export default store

export const userLoaded = () =>
  new Promise(resolve => {
    const unwatch = store.watch(
      state => state.session.user,
      user => {
        Vue.nextTick(() => {
          if (user && user.id) {
            resolve({ user, unwatch })
          }
        })
      },
      { immediate: true }
    )
  })
