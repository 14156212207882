<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      d="M17.5 19.75C17.5 19.3358 17.1642 19 16.75 19C16.3358 19 16 19.3358 16 19.75V24.75C16 25.1642 16.3358 25.5 16.75 25.5C17.1642 25.5 17.5 25.1642 17.5 24.75V19.75Z"
      fill="currentColor"
    />
    <path
      d="M19.75 23C20.1642 23 20.5 23.3358 20.5 23.75V24.75C20.5 25.1642 20.1642 25.5 19.75 25.5C19.3358 25.5 19 25.1642 19 24.75V23.75C19 23.3358 19.3358 23 19.75 23Z"
      fill="currentColor"
    />
    <path
      d="M23.5 21.75C23.5 21.3358 23.1642 21 22.75 21C22.3358 21 22 21.3358 22 21.75V24.75C22 25.1642 22.3358 25.5 22.75 25.5C23.1642 25.5 23.5 25.1642 23.5 24.75V21.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.75 10C17.4912 10 16.43 10.8457 16.1035 12H14.75C14.0207 12 13.3212 12.2897 12.8055 12.8055C12.2897 13.3212 12 14.0207 12 14.75V26.75C12 27.4793 12.2897 28.1788 12.8055 28.6945C13.3212 29.2103 14.0207 29.5 14.75 29.5H24.75C25.4793 29.5 26.1788 29.2103 26.6945 28.6945C27.2103 28.1788 27.5 27.4793 27.5 26.75V14.75C27.5 14.0207 27.2103 13.3212 26.6945 12.8055C26.1788 12.2897 25.4793 12 24.75 12H23.3965C23.07 10.8457 22.0088 10 20.75 10H18.75ZM18.75 11.5C18.0596 11.5 17.5 12.0596 17.5 12.75C17.5 13.4404 18.0596 14 18.75 14H20.75C21.4404 14 22 13.4404 22 12.75C22 12.0596 21.4404 11.5 20.75 11.5H18.75ZM24.75 13.5H23.3965C23.07 14.6543 22.0088 15.5 20.75 15.5H18.75C17.4912 15.5 16.43 14.6543 16.1035 13.5H14.75C14.4185 13.5 14.1005 13.6317 13.8661 13.8661C13.6317 14.1005 13.5 14.4185 13.5 14.75V26.75C13.5 27.0815 13.6317 27.3995 13.8661 27.6339C14.1005 27.8683 14.4185 28 14.75 28H24.75C25.0815 28 25.3995 27.8683 25.6339 27.6339C25.8683 27.3995 26 27.0815 26 26.75V14.75C26 14.4185 25.8683 14.1005 25.6339 13.8661C25.3995 13.6317 25.0815 13.5 24.75 13.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAccountingReports"
}
</script>
